import React from 'react';
import TextField from '@mui/material/TextField';

import MailingDialog from 'components/mailing/MailingDialog';
import SearchByManufacturer from 'components/search/manufacturer/SearchByManufacturer';
import CategoryTree from 'components/categorytree/CategoryTree';
import BaseComponent from 'base';

class SidebarView extends BaseComponent {
  constructor(props) {
    super(props);
  }

  state = {
    manufacturer: '',
    searchText: '',
    categoryId: null,
  };

  componentDidUpdate(prevProps) {
    Object.keys(this.state).forEach((stateKey) => {
      if (prevProps[stateKey] !== this.props[stateKey]) {
        const stateUpdate = {};
        stateUpdate[stateKey] = this.props[stateKey];
        setTimeout(() => this.setState(stateUpdate));
      }
    });
  }

  onSearchChange(searchText) {
    if (this.props.onSearchTextChanged) {
      this.debounce(this.props.onSearchTextChanged, 300)(searchText);
    }
    this.setState({ searchText });
  }

  onCategoryChanged(categoryId) {
    if (this.props.onCategoryChanged) {
      this.props.onCategoryChanged(categoryId);
    }
    this.setState({ categoryId });
  }

  onManufacturerChanged(manufacturer) {
    if (this.props.onManufacturerChanged) {
      this.props.onManufacturerChanged(manufacturer);
    }
    this.setState({ manufacturer });
  }

  onUserListLoaded(products, categoryId) {
    if (this.props.onUserListLoaded) {
      this.props.onUserListLoaded(products, categoryId);
    }

    this.onCategoryChanged(categoryId);
  }

  onResetClicked() {
    this.onManufacturerChanged('');
    this.onCategoryChanged('');
    this.onSearchChange('');

    if (this.props.onResetClicked) {
      this.props.onResetClicked();
    }
  }

  renderButtons() {
    return (
      <div className="left">
        <MailingDialog className={'actions'} />
      </div>
    );
  }

  render() {
    return (
      <div className={'sidenavContent'}>
        {/* {this.renderButtons()} */}

        <SearchByManufacturer
          onChange={this.onManufacturerChanged.bind(this)}
          value={this.state.manufacturer}
        />

        <TextField
          label="Fritextsök bland produkter"
          fullWidth
          variant="standard"
          onChange={(evt) => this.onSearchChange(evt.target.value)}
        />

        <CategoryTree
          selectedId={this.state.categoryId}
          onChange={this.onCategoryChanged.bind(this)}
        />
      </div>
    );
  }
}

export default SidebarView;
