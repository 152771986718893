import React from 'react';
import {Paper, Box} from '@mui/material';
import { chainTypeToString } from '../../../services/Utils';
import AmCharts from '@amcharts/amcharts3-react';

class LastPricesChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      boxChartData: [],
      activeStoresCount: 0,
      yAxisTitle: 'Pris',
      ticksuffix: ' kr',
      title: this.props.product.name,
      id: this.props.product.id,
      fileName: this.props.product.name + ' ' + new Date().toJSON().slice(0, 10),
      plotLayout: {},
      isActive: true,
    };
  }

  componentDidMount() {
    if (this.props.product) {
      this.createDataForChart(this.props.product);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.product !== this.props.product ||
      prevProps.showDiscountPrices !== this.props.showDiscountPrices ||
      prevProps.showComparePrices !== this.props.showComparePrices ||
      this.props.product.getActiveStores().length !== this.state.activeStoresCount
    ) {
      this.createDataForChart(this.props.product);
    }
  }

  unitConvertion = {
    1: 'kg', // Weight units, converted to kilograms
    2: 'l', // Volume units, converted to liters
    3: 'st', // Singe units, not used for now
    4: 'm', // Length units, converted to meters
  };

  getStandardLayout = () => {
    const layout = {
      width: '100%',
      height: this.props.height || 300,
    };

    return layout;
  };

  createDataForChart(product) {
    const chartDataDict = {};
    const DELIMITER = '&';

    const unit = this.getUnit(product);
    const isUnitOk = unit !== 3 && unit !== 4 && product.prices.every((x) => x.every((y) => y.unit === unit));
    if (this.props.showComparePrices && isUnitOk) {
      this.setState({
        yAxisTitle: 'Jämförpris',
        ticksuffix: ' kr/' + this.unitConvertion[unit],
        title: product.name + ' - Visar jämförpris',
        plotLayout: this.getStandardLayout(),
      });
    } else {
      this.setState({
        yAxisTitle: 'Pris',
        ticksuffix: ' kr',
        title: product.name,
        plotLayout: this.getStandardLayout(),
      });
    }

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const isActiveArray = [];
    const activeStores = product.getActiveStores();
    activeStores.forEach((store) => {
      const chain = store.chain.toString();
      const chainColor = store.color;
      const innerProductPrices = store.getProductPrices(product.id);
      innerProductPrices.forEach((productPrices) => {
        const lastPrice = productPrices[productPrices.length - 1];
        let isActive = true;
        // console.log('last price', lastPrice);
        if (lastPrice.lastDate) {
          const date = new Date(lastPrice.lastDate.substring(0, 10));
          isActive = date >= currentDate;
        }
        isActiveArray.push(isActive);
        if (isActive) {
          // console.log('last price', lastPrice);
          const dictKey = chain + DELIMITER + store.type;
          if (!chartDataDict[dictKey]) {
            chartDataDict[dictKey] = {
              y: [],
              name: '',
              type: 'box',
              color: [],
            };
          }
          let prices;
          if (isUnitOk) {
            prices = this.getLastPriceFormatted(lastPrice, unit);
          } else {
            prices = this.getLastPriceFormatted(lastPrice);
          }
          chartDataDict[dictKey].y = chartDataDict[dictKey].y.concat(prices);
          chartDataDict[dictKey].name = (store.chainName || 'Övrigt') + ' ' + chainTypeToString(store.type);
          if (chainColor !== null) {
            chartDataDict[dictKey].color = chainColor;
          }
        }
      });
    });

    const chartData = Object.keys(chartDataDict)
      .map((key) => chartDataDict[key])
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    const boxChartData = [];
    const fallbackColor = '#ff0000';
    chartData.forEach((data) => {
      boxChartData.push({
        name: data.name,
        high: Math.max.apply(Math, data.y),
        open: this.getPercentile(data.y, 75),
        mid: this.getPercentile(data.y, 50),
        close: this.getPercentile(data.y, 25),
        low: Math.min.apply(Math, data.y),
        color: data.color.length !== 0 ? data.color : fallbackColor,
      });
    });
    this.setState({
      boxChartData,
      activeStoresCount: activeStores.length,
      isActive: isActiveArray.length && isActiveArray.some(x => x === true),
    });
  }

  getUnit(product) {
    return product.prices && product.prices.length > 0 && product.prices[0] && product.prices[0].length > 0 ? product.prices[0][0].unit : -1;
  }

  /* Get any percentile from an array
   used to make boxplot-data since amCharts doesn't have it */
  getPercentile(data, percentile) {
    data.sort((a, b) => {
      return a - b;
    });
    const index = (percentile / 100) * data.length;
    let result;
    if (Math.floor(index) === index) {
      result = (parseFloat(data[index - 1]) + parseFloat(data[index])) / 2;
    } else {
      result = parseFloat(data[Math.floor(index)]);
    }
    return result;
  }

  getLastPriceFormatted(lastPrice, unit) {
    if (lastPrice.discountAmount && this.props.showDiscountPrices) {
      if (this.props.showComparePrices && !!unit) {
        return this.formatAmountToPrice(lastPrice.compareDiscountAmount);
      }
      return this.formatAmountToPrice(lastPrice.discountAmount);
    }
    if (this.props.showComparePrices && !!unit) {
      return this.formatAmountToPrice(lastPrice.compareAmount);
    }
    return this.formatAmountToPrice(lastPrice.amount);
  }

  formatAmountToPrice(amount) {
    const amountFloat = parseFloat(amount);
    if (!isNaN(amountFloat)) {
      return (amountFloat / 100).toFixed(2);
    }
    return 0;
  }

  render() {
    const data = this.state.boxChartData;
    const title = this.state.title;
    const yAxisTitle = this.state.yAxisTtiel;
    const unit = this.state.ticksuffix;
    const id = this.state.id;
    const fileName = this.state.fileName;
    return (
      <Paper elevation={0}>
        {!this.state.isActive && <Box sx={{display: 'flex', justifyContent: 'center', padding: '10em'}}>
          <h4>För att visa nulägesgrafen behöver dagens datum vara med i intervallet samt produkt ha aktuella priser i valda butiker</h4>
        </Box>}
        {this.state.isActive && <AmCharts.React
          style={{
            width: '98%',
            height: this.state.plotLayout.height,
          }}
          options={{
            type: 'serial',
            hideCredits: true,
            theme: 'light',
            titles: [
              {
                text: title,
                size: 15,
              },
            ],
            graphs: [
              {
                type: 'candlestick',
                closeField: 'close',
                fillColorsField: 'color',
                highField: 'high',
                lineColorField: 'color',
                lineAlpha: 1,
                lineThickness: 2,
                lowField: 'low',
                fillAlphas: 0.1,
                openField: 'open',
                title: 'Price:',
                valueField: 'close',
                balloonFunction: function (graphDataItem, graph) {
                  const high = graphDataItem.values.high;
                  const mid = graphDataItem.values.value;
                  const low = graphDataItem.values.low;
                  const values = Object.values(graphDataItem.values);
                  if (values.every((v) => v === values[0])) {
                    return 'Pris: ' + values[0];
                  }
                  return 'Högsta: ' + high + '</br>' + 'Median: ' + mid + '</br>' + 'Lägsta: ' + low;
                },
              },
              {
                type: 'column',
                columnWidth: 0.2,
                valueField: 'high',
                openField: 'high',
                lineColorField: 'color',
                lineThickness: 3,
                showBalloon: false,
                clustered: false,
              },
              {
                type: 'column',
                columnWidth: 0.2,
                valueField: 'low',
                openField: 'low',
                lineColorField: 'color',
                lineThickness: 3,
                showBalloon: false,
                clustered: false,
              },
              {
                type: 'column',
                valueField: 'mid',
                openField: 'mid',
                lineColorField: 'color',
                lineThickness: 3,
                showBalloon: false,
                clustered: false,
              },
            ],
            chartCursor: {
              valueLineEnabled: false,
              valueLineBalloonEnabled: true,
              cursorAlpha: 0,
            },
            categoryField: 'name',
            categoryAxis: {
              title: 'Matbutik',
              gridPosition: 'start',
              tickPosition: 'start',
              tickLength: 10,
              axisAlpha: 0.7,
              gridAlpha: 0,
              autoWrap: true,
            },
            valueAxes: [
              {
                position: 'left',
                title: yAxisTitle,
                unit: unit,
                axisAlpha: 0,
              },
            ],
            dataProvider: data,

            export: {
              enabled: true,
              fileName: fileName,
              removeImages: false,
              forceRemoveImages: false,
              legend: {
                position: 'top', // or "right", "bottom" and "left" are possible
                width: 200, // optional
                height: 200, // optional
              },
              position: 'bottom-left',

              afterCapture: function afterCapture(menuConfig) {
                const imgFromHtml = document.getElementById(id);
                imgFromHtml.crossOrigin = 'anonymous';
                const fabricImage = new fabric.Image(imgFromHtml, {
                  top: 7,
                  left: this.setup.fabric.getWidth() * 0.9,
                });
                this.setup.fabric.add(fabricImage);

                const weekNumber = (today) => {
                  const onejan = new Date(today.getFullYear(), 0, 1);
                  return Math.ceil(((today - onejan) / 86400000 + onejan.getDay() + 1) / 7);
                };

                const text = new fabric.Text('Vecka ' + weekNumber(new Date()) + ' ' + new Date().getFullYear(), {
                  top: 15,
                  left: 15,
                  fontFamily: this.setup.chart.fontFamily,
                  fontWeight: 'bold',
                  fontSize: 15,
                });
                this.setup.fabric.add(text);
              },
            },
          }}
        />}
      </Paper>
    );
  }
}

export default LastPricesChart;
