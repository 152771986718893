import React from 'react';
import AutoComplete from '@mui/material/Autocomplete';
import Backend from '../../../services/Backend';
import BaseComponent from '../../../base';
import { TextField } from '@mui/material';
class SearchByManufacturer extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
    };
  }

  componentDidMount() {
    // const self = this;
    // Backend.fetchBrands()
    //   .then((response) => {
    //     const test = [...new Set(response)];
    //     // this.state.results = response;
    //     self.setState({
    //       results: test,
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }
  onChange(value) {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }
  render() {
    const results = this.state.results.sort((a, b) => -b.localeCompare(a));
    return (
      <div>
        {
          <AutoComplete
            style={{ overflow: 'hidden' }}
            clearOnEscape
            disableClearable
            freeSolo
            id="combo-box-demo"
            options={results}
            onOpen={() => {
              console.log('%c⧭', 'color: #00258c', 'OPEN');
            }}
            //  onChange={this.onChange.bind(this)}
            onInputChange={(event, newInputValue) => {
              this.debounce(this.props.onChange(newInputValue), 500);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Varumärken" variant="standard" />
            )}
            sx={{ width: 300 }}
          />
        }

        <br />
      </div>
    );
  }
}

export default SearchByManufacturer;

/*
     {<AutoComplete
          searchText={this.props.value}
          filter={(searchText, key) => searchText !== '' && key.toLowerCase().startsWith(searchText.toLowerCase())}
          maxSearchResults={5}
          floatingLabelText="Varumärke"
        dataSource={results}
        onUpdateInput={this.onChange.bind(this)}
        fullWidth
        />}

        */
