import moment from 'moment';

/* =============== UTILS ====================== */
// public enum UnitType { NON, MILLIGRAM, MILLILITRE, UNIT, MILLIMETER }
const units = ['', 'mg', 'ml', 'st', 'mm'];

export function unitToString(unitId) {
  return units[unitId || 0] || '';
}

const typeDict = {
  nara: 'Nära',
  supermarket: 'Supermarket',
  kvantum: 'Kvantum',
  maxi: 'Maxi',
  online: 'Online',
  coop: 'Coop',
  stora: 'Stora',
  vardagshandel: 'Vardagshandel',
  prisvärt: 'Prisvärt',
  'Coop Online': 'Coop Online',
  xtra: 'X:-TRA',
};

export function chainTypeToString(type) {
  return typeDict[type] || '';
}

export function getMaxGridCount() {
  const w = window.innerWidth;
  switch (true) {
    case w > 1700:
      return 15;
    case w > 1600:
      return 12;
    case w > 1500:
      return 11;
    case w > 1400:
      return 10;
    case w > 1300:
      return 9;
    case w > 1200:
      return 8;
    case w > 1100:
      return 7;
    default:
      return 6;
  }
}

export function getShortDateWithWeek(dateString) {
  return dateString.substring(2) + '<br>v' + getWeekFromDateString(dateString);
}

export function getWeekFromDateString(dateString) {
  let weeknumber = moment(dateString, 'YYYY-MM-DD').isoWeek();
  return weeknumber;
}

const unitsDisplayFormat = [
  {},
  {
    kg: 1000000,
    g: 1000,
    mg: 1,
  },
  {
    l: 1000,
    cl: 10,
    ml: 1,
  },
  {
    st: 1,
  },
  {
    m: 1000,
    dm: 100,
    cm: 10,
    mm: 1,
  },
];

export function formatProductVolume(volume, unitId) {
  const volumeInt = parseInt(volume, 10);
  const displayFormat = unitsDisplayFormat[unitId || 0] || {};

  for (const unitName in displayFormat) {
    const unitVolume = volumeInt / displayFormat[unitName];

    if (unitVolume >= 1) {
      return `${unitVolume} ${unitName}`;
    }
  }

  return volume;
}

export function getFullProductName(product) {
  let split = getFullProductNameSplit(product);
  return split[1] ? `${split[0]} ${split[1]}` : split[0];
}

export function getFullProductNameSplit(product) {
  const one = `${product.name}`;
  const vol = formatProductVolume(product.amountOfUnits, product.unit);
  let two = '';
  if (vol) {
    two += `${vol}`;
  }
  if (product.varumarke && !one.includes(product.varumarke)) {
    two += `, ${product.varumarke}`;
  }
  return [one, two];
}

export function localAndSessionstorageAvailable() {
  const key = 'availabilityCheck';

  const value = 'yes-or-no';

  try {
    if (typeof localStorage !== 'undefined' && typeof sessionStorage !== 'undefined') {
      localStorage.setItem(key, value);
      sessionStorage.setItem(key, value);

      if (localStorage.getItem(key) === value && sessionStorage.getItem(key)) {
        localStorage.removeItem(key);
        sessionStorage.removeItem(key);

        return true;
      }

      return false;
    }
    return false;
  } catch (e) {
    return false;
  }
}
