import React from 'react';
import SortIcon from '@mui/icons-material/SwapVert';

import './ProductPricesTable.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// Fix for browsers which dont have localeCompare
if (typeof String.prototype.localeCompare === 'undefined') {
  String.prototype.localeCompare = (str) => {
    // eslint-disable-line no-extend-native
    return this === str ? 0 : this > str ? 1 : -1;
  };
}

class ProductPricesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortHeader: '',
      storesMap: {},
      isAsc: false,
      rows: [],
    };
  }

  componentDidMount() {
    console.log('prids!', this.props.products);
    this.setState({products: this.props.products});
  }

  renderRow(row, rowIndex) {
    return (
      <TableRow key={rowIndex}>
        {this.props.columns.map((column, i) => (
          <TableCell key={i}>{row[column.key] || ''}</TableCell>
        ))}
      </TableRow>
    );
  }

  sortByColumn(key) {
    const isAsc = this.state.sortHeader === key ? !this.state.isAsc : true;
    const rows = this.state.products.sort((a, b) =>
      b[key].toString().localeCompare(a[key])
    );

    if (!isAsc) {
      rows.reverse();
    }

    this.setState({
      sortHeader: key,
      isAsc,
      rows,
    });
  }

  renderHeader() {
    return (
      <TableHead>
        <TableRow>
          {' '}
          {this.props.columns.map((column, index) => (
            <TableCell key={index}>
              {column.name}
              <SortIcon
                id={index}
                className="sortIcon"
                onMouseUp={() => this.sortByColumn(column.key, this.state.products)}
              />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  render() {
    if (!this.state.products || !this.state.products.length) {
      return (<div></div>);
    }

    const tableHeight = this.props.height || 'auto';
    return (
      <TableContainer component={Paper}>
        <Table height={tableHeight} stickyHeader>
          {this.renderHeader()}
          <TableBody>
            {this.state.products.map(this.renderRow.bind(this))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default ProductPricesTable;
