import React from 'react';
import BaseComponent from 'base';
import Dialog from '@mui/material/Dialog';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

class ErrorView extends BaseComponent {
  constructor(props) {
    super(props);
  }

  handleClose = () => {
    this.redirect('/');
    location.reload();
  };

  static errors = {
    999: 'Tredjepartscookies måste vara tillåtna för att tjänsten skall fungera',
    401: 'Obehörig åtkomst',
    403: 'Du saknar rättigheter att se den här sidan',
    0: 'Testa att ladda om sidan eller försök igen senare',
  };

  render() {
    const search = queryString.parseUrl(location.href);
    // const name = new URLSearchParams(search).get('code');
    const actions = [
      <Button variant="contained" key={'error'} primary onClick={this.handleClose}>
        Ok
      </Button>,
    ];

    const statusCode = parseInt(search.query.code || '0', 10);
    return (
      <Dialog fullWidth open sx={{ padding: 10 }}>
        <DialogContent>
          {' '}
          <h3>Ett fel har uppstått</h3>
          {ErrorView.errors[statusCode] || ErrorView.errors[0]}
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    );
  }
}

export default ErrorView;
