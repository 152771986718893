import React from 'react';
import StoreDetail from 'components/store/detail/StoreDetail';

export default class StorePaginationHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleStoreCount: 3,
    };
  }

  getChainkey(store) {
    return store.chain + '&' + store.type;
  }

  render() {
    const count = this.state.visibleStoreCount;
    const allStores = [...this.props.stores];
    const activeStores = allStores.filter((x) => x.active).sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    const width = this.props.width;
    // if all stores in a chain are selected, display them as a chain instead with avg price
    const chainMap = allStores
      .map((store) => [this.getChainkey(store), store.active])
      .reduce((arr, chain) => {
        if (!arr[chain[0]]) {
          arr[chain[0]] = { total: 0, active: 0 };
        }
        arr[chain[0]].total++;
        if (chain[1]) {
          arr[chain[0]].active++;
        }
        return arr;
      }, {});

    const activeChains = new Set(Object.keys(chainMap).filter((x) => chainMap[x].total == chainMap[x].active));

    let items = [...activeChains].map((x) => {
      const chainStores = activeStores.filter((store) => x == this.getChainkey(store));
      return chainStores;
    });
    const singleStores = activeStores.filter((x) => !activeChains.has(this.getChainkey(x))).map((x) => [x]);
    if (singleStores.length > 0) {
      items = [...items, ...singleStores];
    }
    
    return (
      <div>
        {items.map((item, index) => (
          <div key={item.length == 1 ? item[0].id : `chainDetail${this.getChainkey(item[0])}`}>
            <StoreDetail
              key={Math.random(1, 9999)}
              stores={item}
              width={width > 0 ? width : 0}
              showDiscountPrices={this.props.showDiscountPrices}
              showComparePrices={this.props.showComparePrices}
              isSameUnit={this.props.isSameUnit}
              showChart={index < count}
            />
          </div>
        ))}
      </div>
    );
  }
}
