import React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Backend from '../../../services/Backend';
import SaveIcon from '@mui/icons-material/SaveAsOutlined';
import './UserListSaveDialog.css';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';

class UserLoadSaveDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      dialogOpen: false,
    };
  }

  onOpenClicked() {
    this.setState({
      dialogOpen: true,
      name: '',
      email: '',
    });
  }

  onCloseClicked() {
    this.setState({
      dialogOpen: false,
    });
  }

  onNameChanged(event) {
    this.setState({
      name: event.target.value,
    });
  }

  onSaveClicked() {
    const products = this.props.products;
    const payload = {
      name: this.state.name,
      email: this.state.email,
      products: products.map((x) => x.id),
    };
    Backend.saveUserList(payload).then(() => {
      if (this.props.onSave) {
        this.props.onSave(this.state.name);
      }
      this.setState({
        name: '',
        dialogOpen: false,
      });
    });
  }

  render() {
    const products = this.props.products || [];

    return (
      <div className={`userlistSave ${this.props.className}`}>
        <Button
          disabled={this.props.disabled}
          onClick={
            this.props.disabled ? () => {} : this.onOpenClicked.bind(this)
          }
          startIcon={<SaveIcon name="floppy-o" />}
        >
          Spara sökning
        </Button>

        <Dialog
          modal={false}
          open={this.state.dialogOpen}
          onRequestClose={this.onCloseClicked.bind(this)}
          fullWidth
        >
          <DialogTitle>Spara sökning</DialogTitle>
          <DialogContent>
            <div className="userListFields">
              <div className="left">
                <div className="chosenProductsText">
                  {`${products.length} valda produkter`}
                </div>
                <TextField
                  id={'save-searches-textfield'}
                  label="Namn"
                  value={this.state.name}
                  variant="standard"
                  onChange={this.onNameChanged.bind(this)}
                />
                {/* <TextField
                  id={'save-searches-textfield'}
                  value={this.state.name}
                  onChange={this.onNameChanged.bind(this)}
                  floatingLabelText={'Namn'}
                  errorText={
                    !this.state.name && 'Skriv ett namn på din sparade sökning'
                  }
                /> */}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            {' '}
            <Button label="Avbryt" onClick={this.onCloseClicked.bind(this)}>
              Avbryt
            </Button>
            <Button
              secondary
              keyboardFocused
              disabled={!this.state.name}
              onClick={this.onSaveClicked.bind(this)}
            >
              Spara
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default UserLoadSaveDialog;
