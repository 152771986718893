import React from "react";
import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";

import LoadingContainer from "../../components/loading/LoadingContainer";
import Backend from "../../services/Backend";
import "./ResultList.css";
import { formatProductVolume } from "../../services/Utils";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
} from "@mui/material";

class ResultList extends React.Component {
  state = {
    lastScrollTop: 0,
  };
  static scrollTriggerPercentage = 0.3;
  onScrollEvent() {
    if (this.ref && this.props.onScrollToBottom) {
      const elementHeight = this.ref.innerHeight || this.ref.offsetHeight;
      const scrollTopTrigger =
        this.ref.scrollTop + elementHeight * ResultList.scrollTriggerPercentage;
      console.log(
        "%c⧭",
        "color: #917399",
        scrollTopTrigger >= this.ref.scrollHeight - elementHeight,
      );

      if (scrollTopTrigger >= this.ref.scrollHeight - elementHeight) {
        const lastScrollTop = this.state.lastScrollTop;
        this.setState(
          {
            lastScrollTop: this.ref.scrollTop,
          },
          () => {
            // Scrolling down
            if (this.state.lastScrollTop > lastScrollTop) {
              this.props.onScrollToBottom();
            }
          },
        );
      }
    }
  }

  toggleProduct(product) {
    if (product) {
      const selectedProducts = this.props.selectedProducts;
      const selectedProductsIds = selectedProducts.map((p) => p.id);

      if (selectedProductsIds.indexOf(product.id) !== -1) {
        if (this.props.onRemoveProduct) {
          this.props.onRemoveProduct(product);
        }
      } else if (this.props.onAddProduct) {
        this.props.onAddProduct(product);
      }
    }
  }

  getDuplicateName(product) {
    if (product.type === 3) {
      return product.name2;
    }
    return formatProductVolume(product.amountOfUnits, product.unit);

    /*
    console.log('get dupename', product);
    if (product === null || product.name2 === null) {
      return null;
    }
    const duplicateProductSuffix = '(Inkluderar GTIN';
    const indexOfSuffix = product.name2.indexOf(duplicateProductSuffix);

    if (indexOfSuffix === -1) {
      return product.name2;
    }
    return product.name2.substring(0, indexOfSuffix);*/
  }

  maxChars(count, text) {
    return text && text.length > count
      ? text.substring(0, count - 3) + "..."
      : text;
  }

  render() {
    const products = this.props.products;
    const selectedProductsIds = this.props.selectedProducts.map(
      (product) => product.id,
    );

    return (
      <div className={this.props.className}>
        {this.props.children}

        <LoadingContainer
          enabled={this.props.loading && products.length === 0}
          className="resultListContainer"
        >
          {this.props.header || ""}
          {!products.length && (
            <div className="noResults">
              {this.props.noResult || "Inga resultat hittades"}
            </div>
          )}

          <div
            className="list"
            ref={(node) => {
              this.ref = node;
            }}
            onScroll={this.onScrollEvent.bind(this)}
          >
            {products.map((product, index) => (
              <List key={`${index}`} dense disablePadding>
                <ListItem
                  disablePadding
                  disableGutters
                  alignItems="flex-start"
                  key={`${product.id}`}
                  id={product.id}
                  value={index}
                >
                  <ListItemButton
                    onClick={() => {
                      this.toggleProduct(product);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          width: 80,
                          height: 80,
                          img: { objectFit: "contain" },
                          bgcolor: Backend.getProductBackgroundColor(product),
                        }}
                        variant="square"
                        alt={product.name}
                        src={Backend.getProductPictureURL(product)}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ paddingLeft: "1%" }}
                      primary={product.name}
                      secondary={
                        <>
                          {this.maxChars(200, this.getDuplicateName(product))}
                          {" - "}
                          {product.gtin && (
                            <span style={{ color: "grey" }}>
                              {product.gtin}
                            </span>
                          )}
                          <br />
                          {product.manufacturer || product.varumarke}
                        </>
                      }
                    />
                    <Checkbox
                      key={product.id}
                      color={"primary"}
                      checked={selectedProductsIds.indexOf(product.id) !== -1}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            ))}

            <LoadingContainer
              className="loadingResults"
              enabled={this.props.loading && products.length > 0}
            />
          </div>
        </LoadingContainer>
      </div>
    );
  }
}

export default ResultList;
