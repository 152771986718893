import React from 'react';
import Backend from './services/Backend';
import { createBrowserHistory } from 'history';

import './base.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const LOCALSTORAGE_SELECTED_KEY = 'selectedProducts';
class BaseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.debounceTimeout = null;
  }
  browserHistory = createBrowserHistory();

  restoreSelectedProducts() {
    try {
      const productsRaw = localStorage.getItem(LOCALSTORAGE_SELECTED_KEY);
      const productsIds = JSON.parse(productsRaw);
      if (!productsIds) {
        // There are no products in localstorage.
        return Promise.resolve([]);
      }
      const promises = productsIds.map((id) => Backend.fetchProduct(id));
      return Promise.all(promises);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  setSelectedProducts(products) {
    const productsIds = products.map((product) => product.id);
    localStorage.setItem(
      LOCALSTORAGE_SELECTED_KEY,
      JSON.stringify(productsIds)
    );
  }

  redirect(uri) {
    this.browserHistory.replace(uri, this.props);
    this.browserHistory.go();
  }

  debounce(func, wait, immediate) {
    const context = this;

    return function _() {
      const args = arguments;
      const later = () => {
        context.debounceTimeout = null;
        if (!immediate) {
          func.apply(this, args);
        }
      };

      const callNow = immediate && !context.debounceTimeout;
      clearTimeout(context.debounceTimeout);

      context.debounceTimeout = setTimeout(later, wait);
      if (callNow) {
        func.apply(this, args);
      }
    };
  }
}

export default BaseComponent;
