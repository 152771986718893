import React from 'react';

import './HoverBox.css';

class HoverBox extends React.Component {
  state = {
    show: false,
  };

  showBox(value) {
    this.setState({
      show: value,
    });
  }

  render() {
    const popupText = this.props.popupText;
    const shouldDisplay = this.state.show && popupText;

    return (<div
      className="hoverBoxContainer"
      onMouseOver={() => this.showBox(true)}
      onMouseOut={() => this.showBox(false)}>

      {this.props.children}

      <div
        className="popupText"
        style={{ display: shouldDisplay ? 'block' : 'none' }}
      >
        {popupText}
      </div>
    </div>);
  }
}

export default HoverBox;
