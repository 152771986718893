import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import './LoadingContainer.css';
class LoadingContainer extends React.Component {
  render() {
    return (
      <div className={this.props.className}>
        {!this.props.enabled && this.props.children}

        {this.props.enabled && (
          <div className="loading">
            {/* <CircularProgress className="animation" /> */}

            <CircularProgress className="animation" />
          </div>
        )}
      </div>
    );
  }
}

export default LoadingContainer;
