import React from 'react';
import Avatar from '@mui/material/Avatar';
import './StoreInfo.css';
import { getStoreImageUrl } from 'services/Images';
import { Card, CardContent, CardHeader } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import IconButton from '@mui/material/IconButton';
import { Collapse } from '@mui/material';

import { chainTypeToString } from 'services/Utils';

class StoreInfo extends React.Component {
  state = {
    expanded: false,
  };
  render() {
    const stores = this.props.stores;
    const color = stores[0].color;
    const imageUrl = getStoreImageUrl(stores[0].chain);
    const { expanded } = this.state;
    const nameAndCity = stores.length == 1 
      ? [stores[0].name, stores[0].city]
      : [(stores[0].chainName || 'Övrigt') + ' ' + chainTypeToString(stores[0].type), `${stores.length} butiker`];
      
    return (
      <Card>
        <CardHeader
          title={nameAndCity[0]}
          subheader={nameAndCity[1]}
          avatar={
            <Avatar
              src={imageUrl}
              size={60}
              sx={{ backgroundColor: color }}
              id={stores[0].id}
            />
          }
          action={
            <IconButton
              size="large"
              edge={'end'}
              onClick={() => {
                this.setState({ expanded: !expanded });
              }}
              aria-label="show more"
            >
              {this.expanded ? (
                <ExpandLess fontSize="inherit" />
              ) : (
                <ExpandMoreIcon fontSize="inherit" />
              )}
            </IconButton>
          }
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {stores.length == 1 && <div className="Container">
              <b> Address:</b> {stores[0].address} <br />
              <b> Postnummer:</b> {stores[0].postCode} <br />
              <b> Stad:</b> {stores[0].city} <br />
              <b> Land:</b> {stores[0].country} <br />
            </div>}
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

export default StoreInfo;
