import React from 'react';
import Paper from '@mui/material/Paper';

import ProductInfo from 'components/products/info/ProductInfo';
import ProductPricePlot from 'components/products/priceplot/ProductPricePlot';

class ProductDetail extends React.Component {
  render() {
    const width = this.props.width;
    const style = {
      margin: '1%',
      minWidth: '98%',
      display: 'inline-block',
    };

    const product = this.props.product;
    const showDiscountPrices = this.props.showDiscountPrices;
    const showComparePrices = this.props.showComparePrices;
    return (
      <Paper style={style} elevation={3}>
        <div className="ProductDetail">
          <ProductInfo selectedProduct={product} />
          <br />
          <ProductPricePlot
            product={product}
            height={400}
            showDiscountPrices={showDiscountPrices}
            showComparePrices={showComparePrices}
          />
          <br />
        </div>
      </Paper>
    );
  }
}

export default ProductDetail;
