import React from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Collapse,
  ListSubheader,
  ListItemIcon,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Backend from 'services/Backend';
import BaseComponent from 'base';

class CategoryTree extends BaseComponent {
  constructor(props) {
    super(props);
  }
  state = { open: {} };

  componentDidMount() {
    Backend.fetchCategories()
      .then((categories) => {
        this.setState({ categories });
      })
      .catch((e) => {
        console.error(e);
      });
  }

  handleTouchTap(selected) {
    if (this.props.onChange && this.state.open[selected.id]) {
      console.log('%c⧭', 'color: #bfffc8', this.props.selectedId);
      console.log('%c⧭', 'color: #bfffc8', selected.id);
      if (
        this.props.selectedId !== selected.id &&
        selected.id !== '00000000-0000-0000-0000-000000000000'
      ) {
        if (this.props.subscribeCategories) {
          this.props.onChange(selected);
        } else {
          this.props.onChange(selected.id);
        }
      } else {
        // this.props.onChange(null);
      }
    }
  }
  isItemSelected(item, selectedId) {
    const isSelected = item.id === selectedId;
    const childSelected = item.children.some((child) =>
      this.isItemSelected(child, selectedId)
    );
    return isSelected || childSelected;
  }
  renderListItem(item, index) {
    const open = this.state.open;
    if (!item) {
      return <span />;
    }

    const hasChildren = item.children.length;
    return (
      <React.Fragment key={item.id}>
        <ListItem alignItems="flex-start" disablePadding>
          {' '}
          <ListItemIcon>
            {hasChildren ? <FolderIcon /> : <InsertDriveFileIcon />}
          </ListItemIcon>
          <ListItemButton
            key={index}
            onClick={() => {
              open[item.id] = !open[item.id];
              this.setState((prevState) => ({
                ...prevState,
                open,
              }));
              this.handleTouchTap(item);
            }}
            selected={this.isItemSelected(item, this.props.selectedId)}
          >
            <ListItemText primary={`${item.name} `} />
            {hasChildren ? (
              open[item.id] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )
            ) : null}
          </ListItemButton>
        </ListItem>
        {hasChildren ? (
          <Collapse
            key={index}
            in={
              item.id === '00000000-0000-0000-0000-000000000000'
                ? true
                : open[item.id]
            }
            timeout="auto"
            unmountOnExit
          >
            <List sx={{ pl: 4 }}>
              {' '}
              {item.children.map(this.renderListItem.bind(this))}
            </List>
          </Collapse>
        ) : (
          <span />
        )}
      </React.Fragment>
    );
  }

  render() {
    if (!this.state.categories) {
      return <span />;
    }

    return <List>{this.renderListItem(this.state.categories, -1)}</List>;
  }
}

export default CategoryTree;
