/* eslint-disable react/jsx-key */
import React from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import Backend from '../../../services/Backend';
import BaseComponent from '../../../base';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import './UserListLoadDialog.css';
import { DialogContent, DialogTitle, DialogActions } from '@mui/material';
import { isObject } from 'lodash';

class UserListLoadDialog extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      lists: [],
    };

    this.fetchUserLists();
  }

  runMigration() {
    return new Promise((resolve) => {
      const localStorageKey = 'savedSearches';
      try {
        const savedLists =
          JSON.parse(localStorage.getItem(localStorageKey)) || [];
        const promises = savedLists.map((list) =>
          Backend.saveUserList({
            name: list.name,
            products: list.products,
          })
        );

        Promise.all(promises).then(
          () => {
            localStorage.removeItem(localStorageKey);
            resolve(true);
          },
          (err) => {
            resolve(false);
            console.warn(err);
          }
        );
      } catch (e) {
        console.warn(e);
        resolve(false);
      }
    });
  }

  fetchUserLists() {
    this.runMigration().then(() => {
      Backend.fetchUserLists().then((lists) => {
        // Used to filter out saved categories for email sbscriptions
        const productLists = lists.filter((x) => x.categoryId === null);
        const selectedId = productLists.length > 0 ? lists[0].id : null;
        this.setState({
          lists: productLists,
        });

        this.setSelectedId(selectedId);
      });
    });
  }

  onOpenDialogClicked() {
    this.setState({
      dialogOpen: true,
    });

    this.fetchUserLists();
  }

  onCloseDialogClicked() {
    this.setState({
      dialogOpen: false,
    });
    this.setSelectedId(null);
  }

  onSelectedChanged(value) {
    this.setSelectedId(value);
  }

  setSelectedId(selectedId) {
    this.setState({
      selectedId,
    });
  }

  getSelectedList() {
    return this.state.lists.filter(
      (list) => list.id === this.state.selectedId
    )[0];
  }

  onOpenClicked() {
    if (this.props.onLoad) {
      Backend.fetchUserListProducts(this.state.selectedId).then((products) => {
        this.props.onLoad(products);
      });
    }

    this.setState({
      dialogOpen: false,
    });
    this.setSelectedId(null);
  }

  onDeleteClicked() {
    const selectedId = this.state.selectedId;
    Backend.deleteUserList(selectedId).then(() => {
      if (this.props.onDelete) {
        this.props.onDelete(selectedId);
      }

      this.setState({
        lists: this.state.lists.filter((list) => list.id !== selectedId),
      });
    });

    this.setSelectedId(null);
  }
  render() {
    return (
      <div className={`userlistOpen ${this.props.className}`}>
        <Button
          onClick={this.onOpenDialogClicked.bind(this)}
          startIcon={<FolderOpenOutlinedIcon />}
        >
          Öppna sökning
        </Button>

        <Dialog
          modal={false}
          open={this.state.dialogOpen}
          onRequestClose={(event, val) => {
            this.onCloseDialogClicked(val.props.value);
          }}
        >
          <DialogTitle>Öppna sökning</DialogTitle>
          <DialogContent>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                defaultValue=""
                variant="standard"
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                value={this.state.selectedId}
                label={'Välj en sparad sökning att öppna'}
                onChange={(event, val) => {
                  this.onSelectedChanged(val.props.value);
                }}
              >
                {this.state.lists.length > 0 ? (
                  this.state.lists.map((option, index) => (
                    <MenuItem value={option.id} key={`${index}`}>
                      {option.name}
                    </MenuItem>
                  ))
                ) : (
                  // DEFAULT
                  <MenuItem disabled value={''}>
                    {''}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            {/* <Select
              label={'Välj en sparad sökning att öppna'}
              value={this.state.selectedId}
              onChange={this.onSelectedChanged.bind(this)}
            >
              {this.state.lists.map((list) => (
                <MenuItem
                  key={list.id}
                  value={list.id}
                  primaryText={list.name}
                />
              ))}
            </Select> */}
          </DialogContent>
          <DialogActions>
            <Button
              className="deleteList"
              style={{ marginRight: '3em' }}
              onClick={this.onDeleteClicked.bind(this)}
              disabled={this.state.selectedId === null}
            >
              Ta bort
            </Button>

            <Button onClick={this.onCloseDialogClicked.bind(this)}>
              Avbryt
            </Button>

            <Button
              onClick={this.onOpenClicked.bind(this)}
              disabled={this.state.selectedId === null}
            >
              Öppna
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default UserListLoadDialog;
