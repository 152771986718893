import React from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import moment from "moment";

// import { Radio, RadioGroup, ToggleButton } from '@mui/material';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "@mui/material/Switch";

import LoadingContainer from "components/loading/LoadingContainer";
import PriceTableDialog from "components/products/pricetable/dialog/PriceTableDialog";
import ProductDetail from "components/products/detail/ProductDetail";
import StorePaginationHandler from "components/store/pagination/StorePaginationHandler";
import LastPricesDetail from "components/lastprices/detail/LastPricesDetail";
import DataManager from "services/DataManager";
import BaseComponent from "base";

import DetailFilterView from "./filter/DetailFilterView";
import "./DetailView.css";
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
const REGULAR_CHART = 1;
const STORE_CHART = 2;
const BAR_CHART = 3;

// 2 years and 4 months
const maxDays = 365 * 2 + 30 * 4;

const DEFAULT_CHART = REGULAR_CHART;

class DetailView extends BaseComponent {
  constructor(props) {
    super(props);
    const endDateFilter = sessionStorage.getItem("endDateFilter");
    const startDateFilter = sessionStorage.getItem("startDateFilter");
    const threeMonthsFilter = this.getDateFilter(3);
    const chart = sessionStorage.getItem("chart");
    // const chartInt = (chart && chart * 1) || DEFAULT_CHART;

    this.state = {
      endDate: endDateFilter || threeMonthsFilter[1],
      startDate: startDateFilter || threeMonthsFilter[0],
      products: [],
      selectedProducts: [],
      showPriceTable: false,
      loading: true,
      currentChart: this.getChart(chart || REGULAR_CHART),
      showDiscountPrices: false,
      showComparePrices: false,
      isSameUnit: false,
      data: new DataManager(),
    };
  }

  componentDidMount() {
    this.restoreSelectedProducts()
      .then((selectedProducts) => {
        this.fetchData(
          selectedProducts,
          this.state.startDate,
          this.state.endDate,
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  fetchData(selectedProducts, startDate, endDate) {
    this.setState(
      {
        data: new DataManager(startDate, endDate),
        products: [],
        selectedProducts,
        loading: true,
      },
      () => {
        this.state.data
          .fetchProductPrices(selectedProducts)
          .then((products) => {
            const stores = this.state.data.getActiveStores();
            const isSameUnit = this.getIsSameUnitForStore(stores);
            this.setState({
              products,
              loading: false,
              isSameUnit,
            });
            this.setChart(
              this.state.currentChart.constant !== null
                ? this.state.currentChart.constant
                : 1,
            );
          });
      },
    );
  }

  goBack() {
    this.redirect("/");
  }

  openPriceTable() {
    this.setState({
      showPriceTable: true,
    });
  }

  closePriceTable() {
    this.setState({
      showPriceTable: false,
    });
  }

  getDateFilter(months) {
    if (months < 1) {
      months = 3;
    }

    const endDate = moment().format();
    const startDate = moment()
      .subtract(months, "months")
      .startOf("isoWeek")
      .format();

    return [startDate, endDate];
  }

  setDateFilter(months) {
    const [startDate, endDate] = this.getDateFilter(months);

    sessionStorage.setItem("endDateFilter", endDate);
    sessionStorage.setItem("startDateFilter", startDate);

    this.setState({ endDate, startDate }, () => {
      this.fetchData(this.state.selectedProducts, startDate, endDate);
    });
  }

  setChart(graph) {
    sessionStorage.setItem("chart", graph);
    const newChart = this.getChart(graph);
    this.setState({
      currentChart: newChart,
    });
  }

  getChart(graph) {
    let newChart = null;
    switch ("" + graph) {
      case "" + STORE_CHART:
        // eslint-disable-next-line no-case-declarations
        if (this.state) {
          const stores = this.state.data.getActiveStores();
          const isSameUnit = this.getIsSameUnitForStore(stores);
          if (!isSameUnit && this.state.showComparePrices) {
            this.setState({ showComparePrices: false });
          }
        }
        newChart = {
          constant: STORE_CHART,
          func: this.renderStoreContent.bind(this),
        };
        break;
      case "" + BAR_CHART:
        console.log("BAR_CHART", graph);
        newChart = {
          constant: BAR_CHART,
          func: this.renderBoxChartContent.bind(this),
        };
        break;
      case "" + REGULAR_CHART:
        console.log("REGULAR_CHART", graph);
        newChart = {
          constant: REGULAR_CHART,
          func: this.renderProductsContent.bind(this),
        };
        break;
      default:
        console.log("DEFAULT REGULAR_CHART", graph);
        newChart = {
          constant: REGULAR_CHART,
          func: this.renderProductsContent.bind(this),
        };
        break;
    }

    return newChart;
  }

  getIsSameUnitForStore(stores) {
    const isStoresOk = [];
    for (let i = 0; i < stores.length; i++) {
      isStoresOk[i] = this.getIsSameUnitForProducts(
        stores[i].getActiveProducts(),
      );
    }
    return isStoresOk.every((v) => Boolean(v));
  }

  getIsSameUnitForProducts(products) {
    const units = [];
    const isUnitsOk = [];
    for (let i = 0; i < products.length; i++) {
      units[i] =
        products[i].prices &&
        products[i].prices.length > 0 &&
        products[i].prices[0] &&
        products[i].prices[0].length > 0
          ? products[i].prices[0][0].unit
          : -1;
      isUnitsOk[i] =
        units[i] !== 3 &&
        units[i] !== 4 &&
        products[i].prices.every((x) => x.every((y) => y.unit === units[i]));
    }
    const sameUnits = (units) => units.every((v) => v === units[0]);
    const allUnitsOk = (isUnitsOk) => isUnitsOk.every((v) => Boolean(v));
    return sameUnits(units) && allUnitsOk(units);
  }

  toggleDiscountPrices(event, isInputChecked) {
    this.setState({
      showDiscountPrices: !isInputChecked,
    });
  }
  toggleComparePrices(event, isInputChecked) {
    this.setState({
      showComparePrices: isInputChecked,
    });
  }

  onChangeStartDate(startDate) {
    let endDate = moment(this.state.endDate);
    const diffDays = endDate.diff(startDate, "days");

    if (diffDays < 0) {
      const currentDiffDays = endDate.diff(this.state.startDate, "days");
      endDate = moment(startDate).add(currentDiffDays, "days");
    }

    if (diffDays > maxDays) {
      endDate = moment(startDate).add(maxDays, "days");
    }

    sessionStorage.setItem("endDateFilter", endDate);
    sessionStorage.setItem("startDateFilter", startDate);

    this.setState({ endDate, startDate }, () => {
      this.fetchData(this.state.selectedProducts, startDate, endDate);
    });
  }

  onChangeEndDate(endDate) {
    let startDate = moment(this.state.startDate);
    const diffDays = moment(endDate).diff(startDate, "days");

    if (diffDays < 0) {
      const currentDiffDays = moment(endDate).diff(
        this.state.startDate,
        "days",
      );
      startDate = moment(endDate).subtract(currentDiffDays, "days");
    }

    if (diffDays > maxDays) {
      startDate = moment(endDate).subtract(maxDays, "days");
    }

    sessionStorage.setItem("endDateFilter", endDate);
    sessionStorage.setItem("startDateFilter", startDate);

    this.setState({ endDate, startDate }, () => {
      this.fetchData(this.state.selectedProducts, startDate, endDate);
    });
  }

  renderStoreContent() {
    const stores = this.state.data.getStores();
    return (
      <StorePaginationHandler
        stores={stores}
        showDiscountPrices={this.state.showDiscountPrices}
        showComparePrices={this.state.showComparePrices}
        isSameUnit={this.state.isSameUnit}
      />
    );
  }

  renderProductsContent() {
    const activeProducts = this.state.data.getActiveProducts();
    return activeProducts.map((product) => (
      <div key={product.id}>
        <ProductDetail
          product={product}
          showDiscountPrices={this.state.showDiscountPrices}
          showComparePrices={this.state.showComparePrices}
        />
      </div>
    ));
  }

  renderBoxChartContent() {
    const activeProducts = this.state.data.getActiveProducts();
    return activeProducts.map((product) => (
      <div key={product.id}>
        <LastPricesDetail
          product={product}
          showDiscountPrices={this.state.showDiscountPrices}
          showComparePrices={this.state.showComparePrices}
        />
      </div>
    ));
  }

  renderPriceTable() {
    const activeProducts = this.state.data.getActiveProducts();
    return (
      <PriceTableDialog
        open={this.state.showPriceTable}
        onClose={this.closePriceTable.bind(this)}
        products={activeProducts}
      />
    );
  }

  renderSidenav() {
    const { value } = this.state.currentChart;

    const chart = sessionStorage.getItem("chart");
    const chartInt = (chart && chart * 1) || DEFAULT_CHART;
    const defaultChart = (value && value.constant) || chartInt;
    return (
      <div className={"sidenavContent"}>
        <div className={"buttons"}>
          <Button
            variant="contained"
            className={"button"}
            onClick={this.goBack.bind(this)}
            color="error"
          >
            Tillbaka
          </Button>

          <div className="flex" />

          <Button
            variant="contained"
            color="error"
            className={"button"}
            disabled={this.state.loading}
            onClick={this.openPriceTable.bind(this)}
          >
            PrisTabell
          </Button>
        </div>

        <div className="datepicker">
          <div>
            <label>Från datum</label>
            <DatePicker
              selected={moment(this.state.startDate).toDate()}
              selectsStart
              showWeekNumbers
              calendarStartDay={1}
              startDate={moment(this.state.startDate).toDate()}
              endDate={moment(this.state.endDate).toDate()}
              onChange={this.onChangeStartDate.bind(this)}
              dateFormat="yyyy-MM-dd"
              maxDate={moment().toDate()}
            />
          </div>

          <div className="datepickerEnd">
            <label>Till datum</label>
            <DatePicker
              selected={moment(this.state.endDate).toDate()}
              selectsEnd
              showWeekNumbers
              popperPlacement="bottom-end"
              calendarStartDay={1}
              startDate={moment(this.state.startDate).toDate()}
              endDate={moment(this.state.endDate).toDate()}
              minDate={this.state.startDate}
              onChange={this.onChangeEndDate.bind(this)}
              dateFormat="yyyy-MM-dd"
              maxDate={moment().toDate()}
            />
          </div>
        </div>
        <div>
          <Box
            sx={{
              padding: "0 1em",
              fontWeight: "bold",
              fontSize: "14px",
              color: "rgba(0, 0, 0, 0.54)",
            }}
          >
            Snabbfilter
          </Box>
        </div>
        <Box sx={{ display: "flex", padding: "0 1em", gap: "0.5em" }}>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={() => this.setDateFilter(3)}
          >
            3 mån
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={() => this.setDateFilter(12)}
          >
            12 mån
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={() => this.setDateFilter(27)}
          >
            27 mån
          </Button>
        </Box>
        <List>
          <ListItem alignItems="flex-start">
            <FormControl component="fieldset" variant="standard">
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={defaultChart}
                value={value}
                name="graphRadios"
                disabled={this.state.loading}
                onChange={(evt, val) => {
                  this.setChart(+val);
                }}
              >
                <FormControlLabel
                  control={<Radio />}
                  value={REGULAR_CHART}
                  label="Produktgraf"
                />
                <FormControlLabel
                  control={<Radio />}
                  value={STORE_CHART}
                  label="Butiksgraf"
                />
                <FormControlLabel
                  value={BAR_CHART}
                  label="Nulägesgraf"
                  control={<Radio />}
                />
              </RadioGroup>
            </FormControl>
          </ListItem>
        </List>

        <List>
          <ListItem alignItems="flex-start">
            <ListItemText> Visa bara ordinarie pris</ListItemText>

            <ListItemSecondaryAction>
              {" "}
              <Switch
                edge="end"
                inputProps={{
                  "aria-labelledby": "switch-list-label-bluetooth",
                }}
                disabled={this.state.loading}
                onChange={this.toggleDiscountPrices.bind(this)}
                defaultChecked
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText> Visa jämförpris</ListItemText>

            <ListItemSecondaryAction>
              {" "}
              <Switch
                edge="end"
                inputProps={{
                  "aria-labelledby": "switch-list-label-bluetooth",
                }}
                /* disabled={
                  this.state.loading ||
                  !this.state.isSameUnit
                } */
                onChange={this.toggleComparePrices.bind(this)}
                checked={this.state.showComparePrices}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>

        <LoadingContainer enabled={this.state.loading}>
          <DetailFilterView
            products={this.state.products}
            storesData={this.state.data.getStores()}
            onStoresDataUpdated={() => this.forceUpdate()}
            onProductsUpdated={() => this.forceUpdate()}
            chartType={this.state.currentChart.constant}
          />
        </LoadingContainer>
      </div>
    );
  }

  render() {
    return (
      <div className={"container"}>
        <LoadingContainer
          enabled={this.state.loading}
          className="loadingCharts"
        >
          {this.state.currentChart.func()}
        </LoadingContainer>

        {this.renderPriceTable()}
        <Drawer variant="permanent" width="36%" className={"sidenav"} open>
          {this.renderSidenav()}
        </Drawer>
      </div>
    );
  }
}

export default DetailView;
