/* eslint-disable react/jsx-key */
import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ListItemIcon,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import FontAwesome from 'react-fontawesome';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Checkbox from '@mui/material/Checkbox';

import CategoryTree from '../../components/categorytree/CategoryTree';
import Backend from '../../services/Backend';
import BaseComponent from '../../base';

import './MailingDialog.css';

class MailingDialog extends BaseComponent {
  state = null;
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      lists: [],
      email: null,
      category: {},
      subscribeLists: [],
      subscribeCategories: [],
      prevSubscribeLists: [],
      prevSubscribeCategories: [],
    };
    this.fetchUserLists();
  }

  /*
 dialogOpen: false,
  lists: [],
  email: null,
  category: {},
  subscribeLists: [],
  subscribeCategories: [],
  prevSubscribeLists: [],
  prevSubscribeCategories: [],
*/
  runMigration() {
    return new Promise((resolve) => {
      const localStorageKey = 'savedSearches';
      try {
        const savedLists =
          JSON.parse(localStorage.getItem(localStorageKey)) || [];
        const promises = savedLists.map((list) =>
          Backend.saveUserList({
            name: list.name,
            products: list.products,
          })
        );

        Promise.all(promises).then(
          () => {
            localStorage.removeItem(localStorageKey);
            resolve(true);
          },
          (err) => {
            resolve(false);
            console.warn(err);
          }
        );
      } catch (e) {
        console.warn(e);
        resolve(false);
      }
    });
  }

  fetchUserLists() {
    this.runMigration().then(() => {
      Backend.fetchUserLists().then((lists) => {
        const subscribeLists = lists.filter(
          (list) =>
            list.email !== '' && list.email !== null && list.products.length > 0
        );
        const subscribeCategories = lists.filter(
          (list) =>
            list.email !== '' && list.email !== null && list.categoryId !== null
        );
        let email = null;
        if (subscribeLists.length > 0) {
          email = subscribeLists[0].email;
        } else if (subscribeCategories.length > 0) {
          email = subscribeCategories[0].email;
        }
        // this.state = {
        //   email: email,
        //   lists: lists.filter((list) => list.categoryId === null),
        //   subscribeLists: subscribeLists,
        //   subscribeCategories: subscribeCategories,
        //   prevSubscribeLists: subscribeLists,
        //   prevSubscribeCategories: subscribeCategories,
        // };
        this.setState({
          email: email,
          lists: lists.filter((list) => list.categoryId === null),
          subscribeLists: subscribeLists,
          subscribeCategories: subscribeCategories,
          prevSubscribeLists: subscribeLists,
          prevSubscribeCategories: subscribeCategories,
        });
      });
    });
  }

  onOpenDialogClicked() {
    this.setState({
      dialogOpen: true,
    });

    this.fetchUserLists();
  }

  onCloseDialogClicked() {
    this.setState({
      dialogOpen: false,
    });
  }
  onChangeEmail(email) {
    this.setState({ email });
  }

  onSaveClicked() {
    // Save all new categories and update email if necessary
    this.state.subscribeCategories.forEach((category) => {
      if (
        category.id === null ||
        this.state.prevSubscribeCategories.every((x) => x.id !== category.id) ||
        category.email !== this.state.email
      ) {
        const payload = {
          id: category.id,
          name: category.name,
          email: this.state.email,
          products: [],
          categoryId: category.categoryId,
        };

        Backend.saveUserList(payload).then(() => {
          if (this.props.onSave) {
            this.props.onSave(this.state.name);
          }
        });
      }
    });

    // Save all new product lists and update email if necessary
    this.state.subscribeLists.forEach((list) => {
      if (
        list.id === null ||
        this.state.prevSubscribeLists.every((x) => x.id !== list.id) ||
        list.email !== this.state.email
      ) {
        const payload = {
          id: list.id,
          name: list.name,
          email: this.state.email,
          products: list.products,
        };
        Backend.saveUserList(payload);
      }
    });

    // Delete userLists for all unsubscribed categories
    this.state.prevSubscribeCategories.forEach((category) => {
      if (this.state.subscribeCategories.every((x) => x.id !== category.id)) {
        Backend.deleteUserList(category.id);
      }
    });

    // Remove email for all unsubscribed lists
    this.state.prevSubscribeLists.forEach((list) => {
      if (this.state.subscribeLists.every((x) => x.id !== list.id)) {
        list.email = '';
        Backend.saveUserList(list);
      }
    });

    // Close dialog
    this.setState({
      name: '',
      dialogOpen: false,
    });
  }

  isValidEmail(email) {
    // eslint-disable-next-line
    const regex =
      // eslint-disable-next-line max-len, no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@“]+(\.[^<>()\[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email && regex.test(email);
  }

  onSubscribeCategoryChange(category) {
    if (category) {
      if (
        this.state.subscribeCategories.every(
          (x) => x.categoryId !== category.id
        )
      ) {
        this.setState({
          subscribeCategories: [
            ...this.state.subscribeCategories,
            { categoryId: category.id, name: category.name },
          ],
        });
      } else {
        this.setState({
          subscribeCategories: this.state.subscribeCategories.filter(
            (x) => x.categoryId !== category.id
          ),
        });
      }
    }
  }
  onSubscribeListSelect(list) {
    if (list) {
      if (this.state.subscribeLists.every((x) => x.id !== list.id)) {
        console.log('selected: ' + list.id);
        if (list.products.length === 0 && list.categoryId !== null) {
          this.setState({
            subscribeCategories: [...this.state.subscribeCategories, list],
          });
        } else {
          this.setState({
            subscribeLists: [...this.state.subscribeLists, list],
          });
        }
      } else {
        this.setState({
          subscribeLists: this.state.subscribeLists.filter(
            (x) => x.id !== list.id
          ),
        });
      }
    }
  }

  renderLists() {
    return this.state.lists.map((list) => <li id={list.id}>{list.name}</li>);
  }

  render() {
    const email = this.state.email;
    return (
      <div className={`mailing ${this.props.className}`}>
        <Button
          variant="outlined"
          onClick={this.onOpenDialogClicked.bind(this)}
          startIcon={<FontAwesomeIcon icon={faEnvelopeOpen} />}
        >
          Veckouppdateringar
        </Button>
        {/* <Button
          label="Veckouppdateringar"
          onClick={this.onOpenDialogClicked.bind(this)}
          icon={<FontAwesome name="envelope-o" />}
        /> */}
        <Dialog
          modal={false}
          open={this.state.dialogOpen}
          onRequestClose={this.onCloseDialogClicked.bind(this)}
        >
          <DialogTitle id="responsive-dialog-title">
            {'Hantera email med veckouppdateringar'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Välj sökningar och kategorier och få uppdateringar på email när
              prisändringar sker (skickas som ett excel-dokument) antingen från
              hela kategorier eller från dina sparade sökningar.
            </DialogContentText>

            <div className="subHeaders">
              <h5 className="mailingSubHeader">Kategorier</h5>
              <h5 className="mailingSubHeader">Dina sparade sökningar</h5>
            </div>
            <div className="panes">
              <div className="mailingCategoryTree">
                <CategoryTree
                  selectedId={this.state.categoryId}
                  onChange={this.onSubscribeCategoryChange.bind(this)}
                  subscribeCategories={this.state.subscribeCategories}
                />
              </div>

              <div className="mailingResultList">
                <List>
                  {this.state.lists.map((list) => (
                    <ListItem
                      key={list.id}
                      id={list.id}
                      onClick={() => this.onSubscribeListSelect(list)}
                      secondaryAction={
                        <Checkbox
                          edge="start"
                          checked={this.state.subscribeLists.some(
                            (x) => x.id === list.id
                          )}
                          tabIndex={-1}
                          disableRipple
                        />
                      }
                    >
                      {list.name}
                    </ListItem>
                  ))}
                </List>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <TextField
              className="emailTextField"
              variant="standard"
              fullWidth
              value={email}
              label={'E-post adress för uppdateringar'}
              onChange={(event) => this.onChangeEmail(event.target.value)}
            />
            <Button
              onClick={this.onCloseDialogClicked.bind(this)}
              color="error"
            >
              Avbryt
            </Button>

            <Button
              color="success"
              disabled={
                !this.isValidEmail(this.state.email) &&
                (this.state.subscribeCategories.length > 0 ||
                  this.state.subscribeLists.length > 0)
              }
              onClick={this.onSaveClicked.bind(this)}
            >
              Spara
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default MailingDialog;

/*
    <ListItemIcon>
                   <Checkbox
                     edge="start"
                     checked={this.state.subscribeLists.some(
                      (x) => x.id === list.id
                    )}
                     tabIndex={-1}
                     disableRipple
                     inputProps={{ 'aria-labelledby': labelId }}
                   />
                 </ListItemIcon>
                 */
