import React from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import StoreInfo from 'components/store/info/StoreInfo';
import StorePricePlot from 'components/store/plot/StorePricePlot';
import './StoreDetail.css';

class StoreDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showChart: props.showChart,
    };
  }

  // componentDidUpdate(prevProps) {
  //   if (this.state.showChart !== this.props.showChart) {
  //     this.setState({ showChart: this.props.showChart });
  //   }
  // }

  toggleChartVisible() {
    const showChart = this.state.showChart;

    this.setState({ showChart: !showChart });
  }
  renderChart() {
    const showDiscountPrices = this.props.showDiscountPrices;
    const showComparePrices = this.props.showComparePrices;
    const isSameUnit = this.props.isSameUnit;
    const width = this.props.width;
    if (this.state.showChart) {
      return (
        <StorePricePlot
          className={'storepriceplot'}
          stores={this.props.stores}
          width={width}
          height={300}
          showDiscountPrices={showDiscountPrices}
          showComparePrices={showComparePrices}
          isSameUnit={isSameUnit}
        />
      );
    }
    return (
      <Button className={'showStoreChartButton'} onClick={this.toggleChartVisible.bind(this)} fullWidth>
        {' '}
        Ladda graf
      </Button>
    );
  }

  render() {
    const style = {
      margin: '0.5em',
      minWidth: '98%',
      display: 'inline-block',
    };

    return (
      <Paper style={style} elavation={3}>
        <div>
          <StoreInfo stores={this.props.stores} />
          {this.renderChart()}
        </div>
      </Paper>
    );
  }
}

export default StoreDetail;
