
class Store {
  color = '#000000';
  active = true;

  prices = [];
  productIds = [];
  productPrices = {};

  constructor(manager, store, chainNames) {
    Object.assign(this, store);
    this.chainName = (chainNames || [])[this.chain];
    this.manager = manager;
  }

  getProducts() {
    return this.manager.getProductsByIds(this.productIds);
  }

  getActiveProducts() {
    return this.getProducts().filter(product => product.active);
  }

  getProductPricesFullRange(productId, priceDates) {
    const prices = this.getProductPrices(productId);
    return this.manager.getPricesFullRange(prices, priceDates);
  }

  getProductPrices(productId) {
    return this.productPrices[productId] || [];
  }

  addProduct(productId, prices) {
    this.productPrices[productId] = prices;
    this.productIds.push(productId);
    this.prices.push.apply(this.prices, prices);
  }
}

export default Store;
