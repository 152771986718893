import dateFormat from 'dateformat';

class Price {
  storeId = null;

  constructor(price, storeId, unit, amountOfUnits) {
    Object.assign(this, price);
    const unitConvertion = {
      1 : 1000000, // Weight units, converted to kilograms
      2 : 1000, // Volume units, converted to liters
      3 : 1, // Singe units, not used for now
      4 : 1000, // Length units, converted to meters
    };

    if (!this.compareAmount || this.compareUnit != unit) {
      this.compareAmount = this.amount / (amountOfUnits / unitConvertion[unit]);
    }
    
    if (!this.compareDiscountAmount || this.compareUnit != unit) {
      this.compareDiscountAmount =
        this.discountAmount ? this.discountAmount / (amountOfUnits / unitConvertion[unit]) : null;
    }

    this.unit = unit;
    this.storeId = storeId;
    this.datetime = new Date(this.date);
    this.datetime.setHours(0, 0, 0, 0);
    this.lastDateTime = this.lastDate ? new Date(this.lastDate) : null;
    if (this.lastDateTime) {
      this.lastDateTime.setDate(this.lastDateTime.getDate() + 1);
      this.lastDateTime.setHours(0, 0, 0, 0);
    }
  }

  setDate(date) {
    this.date = dateFormat(date, 'yyyy-mm-dd');
    this.datetime = new Date(this.date);
    this.datetime.setHours(0, 0, 0, 0);
  }

  clone() {
    return Object.assign(Object.create(this), this);
  }
}

export default Price;
