import React from 'react';
import Paper from '@mui/material/Paper';
import dateFormat from 'dateformat';
import AmCharts from '@amcharts/amcharts3-react';
import { chainTypeToString, getMaxGridCount, getWeekFromDateString, getShortDateWithWeek } from 'services/Utils';

class ProductPricePlot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphs: [],
      plotData: [],
      product: {},
      activeStoresCount: 0,
      weeksCount: 5,
      id: this.props.product.id,
      fileName:
        this.props.product.name + ' ' + new Date().toJSON().slice(0, 10),
      plotLayout: {
        title: '',
        xaxis: {
          title: '',
        },
        yaxis: {
          title: '',
          ticksuffix: '',
        },
      },
    };
  }

  componentDidMount() {
    if (this.props.product) {
      this.parseDataForPlot();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.product !== this.props.product ||
      prevProps.showDiscountPrices !== this.props.showDiscountPrices ||
      prevProps.showComparePrices !== this.props.showComparePrices ||
      this.props.product.getActiveStores().length !==
        this.state.activeStoresCount
    ) {
      this.parseDataForPlot();
    }
  }
  static limitAverage = 10;

  unitConvertion = {
    1: 'kg', // Weight units, converted to kilograms
    2: 'l', // Volume units, converted to liters
    3: 'st', // Singe units, not used for now
    4: 'm', // Length units, converted to meters
  };

  parseDataForPlot = () => {
    const product = this.props.product;
    const activeStores = product.getActiveStores();
    const plotLayout = this.createLayoutForProduct(product);

    this.setState({
      activeStoresCount: activeStores.length,
      product: product,
      plotLayout,
      plotData: this.createDataForStores(product, activeStores),
      graphs: this.getGraphs(activeStores, plotLayout),
    });
  };

  formatAmountToPrice(amount) {
    const amountFloat = parseFloat(amount);
    if (!isNaN(amountFloat)) {
      return (amountFloat / 100).toFixed(2);
    }
    return null;
  }

  formatDate(date) {
    return dateFormat(date, 'yyyy-mm-dd');
  }

  getAvgValue(activeStores) {
    return activeStores.length > ProductPricePlot.limitAverage;
  }

  createDataForStores(product, activeStores) {
    const DELIMITER = '&';
    const chartDataDict = {};
    const dataDict = {};

    const unit =
      product.prices &&
      product.prices.length > 0 &&
      product.prices[0] &&
      product.prices[0].length > 0
        ? product.prices[0][0].unit
        : -1;
    const isUnitOk =
      unit !== 3 &&
      unit !== 4 &&
      product.prices.every((x) => x.every((y) => y.unit === unit));

    const avgValue = this.getAvgValue(activeStores);

    const priceDates = activeStores
      .map((store) => store.getProductPrices(product.id))
      .flatMap((cp) => cp)
      .flatMap((prices) => prices)
      .flatMap((p) => {
        if (p.lastDateTime) {
          const db = new Date(p.lastDateTime);
          db.setDate(db.getDate() - 1);
          return [p.datetime, db.datetime, p.lastDateTime];
        }
        return [p.datetime];
      })
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      })
      .map((x) => new Date(x))
      .sort((a, b) => {
        return a - b;
      });
    const weeks = new Set();
    activeStores.forEach((store) => {
      const innerProductPrices = store.getProductPricesFullRange(
        product.id,
        priceDates
      );
      innerProductPrices.forEach((prices, index) => {
        const chain = store.chain.toString();
        let dictKey = store.id + index;
        let name = store.name;

        if (avgValue) {
          dictKey = chain + DELIMITER + store.type;
          name =
            (store.chainName || 'Övrigt') + ' ' + chainTypeToString(store.type);
        }

        chartDataDict[dictKey] = chartDataDict[dictKey] || {
          name: name,
          visible: store.active,
          color: store.color,
        };

        dataDict[dictKey] = dataDict[dictKey] || {};

        prices.forEach((price) => {
          const date = price.date;
          const ret = dataDict[dictKey][date] || {
            prices: null,
            count: 0,
            discount: false,
            onlyMembers: false,
          };

          let addCount = false;

          if (price.discountAmount && this.props.showDiscountPrices) {
            if (price.discountAmount !== null) {
              if (
                this.props.showComparePrices &&
                isUnitOk &&
                price.compareDiscountAmount !== null
              ) {
                ret.prices += price.compareDiscountAmount;
              } else {
                ret.prices += price.discountAmount;
              }
              addCount = true;
            }
            ret.onlyMembers = ret.onlyMembers || price.onlyMembers;
            ret.discount = true;
          } else if (price.amount !== null) {
            if (this.props.showComparePrices && isUnitOk) {
              ret.prices += price.compareAmount;
            } else {
              ret.prices += price.amount;
            }
            addCount = true;
          }

          if (addCount) {
            ret.count++;
          }
          dataDict[dictKey][date] = ret;
        });

        const x = Object.keys(dataDict[dictKey]);
        const y = [];
        const text = [];

        x.forEach((date) => {
          weeks.add(date.substring(0,4) + getWeekFromDateString(date));
          const elem = dataDict[dictKey][date];
          let tempText = '';

          if (elem.discount) {
            tempText = 'Kampanjpris! ';
            if (elem.onlyMembers) {
              tempText += 'Kortvara ';
            }
          }

          if (elem.count > 1) {
            tempText = `(${elem.count} butiker)`;
          }

          text.push(tempText);

          const price = this.formatAmountToPrice(elem.prices / elem.count);
          y.push(price);
        });

        chartDataDict[dictKey].y = y;
        chartDataDict[dictKey].x = x;
        chartDataDict[dictKey].text = text;
      });
    });

    const chartData = Object.keys(chartDataDict)
      .map((key) => chartDataDict[key])
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    const result = this.createChartData(chartData);
    this.setState({
      weeksCount: weeks.size
    });
    return result;
  }

  createChartData(chartData) {
    const map = new Map();

    for (const data of chartData) {
      this.createChartDataHelper(data, map);
    }
    const result = [];
    map.forEach((value, key) => {
      if (value !== null) {
        result.push({ date: key, ...value });
      } else {
        result.push({ date: key });
      }
    });
    return result;
  }

  createChartDataHelper(data, map) {
    for (let i = 0; i < data.x.length; i++) {
      if (data.y[i] !== null) {
        if (map.has(data.x[i])) {
          const currentValue = map.get(data.x[i]);
          const valueToAdd = { [data.name.split(' ').join('')]: +data.y[i] };
          const newValue = { ...currentValue, ...valueToAdd };
          map.set(data.x[i], newValue);
        } else {
          map.set(data.x[i], { [data.name.split(' ').join('')]: +data.y[i] });
        }
      } else if (!map.has(data.x[i])) {
        map.set(data.x[i]);
      }
    }
  }

  createLayoutForProduct(product) {
    const unit =
      product.prices &&
      product.prices.length > 0 &&
      product.prices[0] &&
      product.prices[0].length > 0
        ? product.prices[0][0].unit
        : -1;
    const isUnitOk =
      unit !== 3 &&
      unit !== 4 &&
      product.prices.every((x) => x.every((y) => y.unit === unit));
    let layout;
    if (this.props.showComparePrices && isUnitOk) {
      layout = this.getStandardLayout(unit);
      layout.title = product.name + ' - Visar jämförpris';
    } else {
      layout = this.getStandardLayout();
      layout.title = product.name;
    }
    return layout;
  }

  getStandardLayout = (unit) => {
    const margins = 48;
    const layout = {
      autosize: true,
      width: '98%',

      height: this.props.height || 400,
      showlegend: true,
      margin: {
        r: margins,
        l: margins + 32,
        b: margins,
        t: margins + 48,
        pad: 1,
      },
      xaxis: {
        title: 'Datum',
      },
    };
    if (unit) {
      layout.yaxis = {
        title: 'Jämförpris',
        ticksuffix: ' kr/' + this.unitConvertion[unit],
      };
    } else {
      layout.yaxis = {
        title: 'Pris',
        ticksuffix: ' kr',
      };
    }
    return layout;
  };

  getGraphs(activeStores, plotLayout) {
    const datas = activeStores
      .filter((activeStore) => activeStore.active)
      .reduce(
        (a, b) => ({ ...a, [this.getStoreId(activeStores, b)]: b.color }),
        {}
      );
    return Object.entries(datas)
      .sort((a, b) => (a[0] > b[0] ? 1 : b[0] > a[0] ? -1 : 0))
      .map(([storeId, color]) => this.createGraph(storeId, color, plotLayout));
  }

  getStoreId = (activeStore, store) => {
    const avgValue = this.getAvgValue(activeStore);
    if (avgValue) {
      return (
        (store.chainName || 'Övrigt') + ' ' + chainTypeToString(store.type)
      );
    }
    return store.name;
  };

  createGraph(storeId, color, plotLayout) {
    return {
      id: 'graph' + storeId.split(' ').join(''),
      balloon: {
        verticalPadding: 3,
      },
      balloonText:
        '<b>' + storeId + '</b>: [[value]] ' + plotLayout.yaxis.ticksuffix,
      type: 'step',
      lineThickness: 2,
      lineColor: color,
      bullet: 'square',
      bulletAlpha: 0,
      bulletSize: 2,
      bulletBorderAlpha: 0,
      title: storeId,
      valueField: storeId.split(' ').join(''),
      connect: false,
    };
  }

  render() {
    const id = this.state.id;
    const fileName = this.state.fileName;
    return (
      <Paper elavation={0}>
        <AmCharts.React
          style={{
            width: this.state.plotLayout.width,
            height: this.state.plotLayout.height,
          }}
          options={{
            type: 'serial',
            hideCredits: true,
            titles: [{ text: this.state.plotLayout.title }],
            theme: 'light',
            autoMarginOffset: 25,
            dataProvider: this.state.plotData,
            graphs: this.state.graphs,
            chartCursor: {
              fullWidth: false,
              cursorAlpha: 0.05,
              graphBulletAlpha: 1,
              oneBalloonOnly: true,
            },
            dataDateFormat: 'YYYY-MM-DD',
            categoryField: 'date',
            categoryAxis: {
              minPeriod: 'DD',
              parseDates: false,
              autoGridCount: false,
              showFirstLabel: true,
              showLastLabel: true,
              equalSpacing: false,
              maximumDate: '2018-11-12',
              minimumDate: '2018-10-12',
              gridCount: Math.min(this.state.weeksCount -2, getMaxGridCount()),
              categoryFunction: getShortDateWithWeek,
              gridAlpha: 0,
              dateFormats: [
                {
                  period: 'DD',
                  format: 'YYYY-MM-DD',
                },
                {
                  period: 'WW',
                  format: 'YYYY-MM-DD',
                },
                {
                  period: 'MM',
                  format: 'MMM YYYY',
                },
                {
                  period: 'YYYY',
                  format: 'MMM YYYY',
                },
              ],
            },
            valueAxes: [
              {
                position: 'left',
                title: this.state.plotLayout.yaxis.title,
                unit: this.state.plotLayout.yaxis.ticksuffix,
                axisAlpha: 0,
              },
            ],
            legend: {
              useGraphSettings: true,
              align: 'left',
              autoMargins: true,
              position: 'right',
              valueText: '[[value]]' + this.state.plotLayout.yaxis.ticksuffix,
              valueFunction: function (graphDataItem, graph) {
                if (graph) {
                  if (graph === ' kr') {
                    return '';
                  }
                  return graph;
                }
                return '';
              },
            },
            export: {
              enabled: true,
              fileName: fileName,
              removeImages: false,
              forceRemoveImages: false,
              legend: {
                position: 'top', // or "right", "bottom" and "left" are possible
                width: 200, // optional
                height: 200, // optional
              },
              position: 'bottom-right',
              afterCapture: function afterCapture(menuConfig) {
                const imgFromHtml = document.getElementById(id);
                imgFromHtml.crossOrigin = 'anonymous';
                const fabricImage = new fabric.Image(imgFromHtml, {
                  top: 7,
                  left: this.setup.fabric.getWidth() * 0.8,
                });
                this.setup.fabric.add(fabricImage);

                const weekNumber = (today) => {
                  const onejan = new Date(today.getFullYear(), 0, 1);
                  return Math.ceil(
                    ((today - onejan) / 86400000 + onejan.getDay() + 1) / 7
                  );
                };
                const text = new fabric.Text(
                  'Vecka ' + weekNumber(new Date()),
                  {
                    top: 15,
                    left: 15,
                    fontFamily: this.setup.chart.fontFamily,
                    fontWeight: 'bold',
                    fontSize: 15,
                  }
                );
                this.setup.fabric.add(text);
              },
            },
          }}
        />
      </Paper>
    );
  }
}

export default ProductPricePlot;
