/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import Drawer from '@mui/material/Drawer';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
// import Button from '@mui/material/Button';

import UserListSaveDialog from '../../components/userlist/save/UserListSaveDialog';
import UserListLoadDialog from '../../components/userlist/load/UserListLoadDialog';
import ResultList from '../../components/result/ResultList';
import SidebarView from './sidebar/SidebarView';
import LoadingContainer from '../../components/loading/LoadingContainer';
import Backend from '../../services/Backend';
import BaseComponent from '../../base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import './App.css';
import logo from '../../images/logo.jpg';
import ClearIcon from '@mui/icons-material/ClearOutlined';
import ErrorView from 'containers/error/Error';

const maxSelectedProducts = 20;

class App extends BaseComponent {
  state = {};
  faChartLine = faChartLine;
  isThirdPartyCookiesEnabled = true;

  constructor(props) {
    super(props);

    this.state = {
      // Sidebar data
      manufacturer: '',
      categoryId: null,
      searchText: '',

      // Search
      searchResult: [],
      searchPage: 1,
      searchLoading: false,
      searchSequence: 0,

      // Other
      selectedProducts: [],
      welcomeText: true,
      loading: true,
      snackbar: '',
    };

    this.restoreSelectedProducts()
      .then((products) => {
        this.updateSelectedProducts(products);
      })
      .catch((e) => {
        this.updateSelectedProducts([]);
        console.info(e);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.manufacturer !== this.state.manufacturer || prevState.searchText !== this.state.searchText || prevState.categoryId !== this.state.categoryId) {
      // eslint-disable-next-line
      this.setState(
        {
          searchPage: 1,
        },
        this.searchProducts()
      );
    }
  }

  searchProducts(page) {
    if ((page && page > this.state.searchPage + 1)) {
      return;
    }

    if (!this.state.searchText && !this.state.categoryId && !this.state.manufacturer) {
      this.setState({
        searchResult: [],
      });
      return;
    }
    const newSequence = this.state.searchSequence + 1;
    this.setState(
      {
        searchResult: page ? this.state.searchResult : [],
        searchLoading: true,
        searchSequence: newSequence,
      },
      () => {
        Backend.fetchSearchResults(this.state.searchText, this.state.manufacturer, this.state.categoryId, page || this.state.searchPage)
          .then((result) => {
            if(this.state.searchSequence != newSequence) {
              return;
            }
            const searchResult = [...this.state.searchResult, ...result];
            this.setState({
              searchLoading: false,
              searchResult,
              searchPage: page && result.length > 0 ? page : this.state.searchPage,
            });
          })
          .catch((err) => {
            console.error(err);
          });
      }
    );
  }

  handleScrollToBottom() {
    if(!this.state.searchLoading) {
      this.searchProducts(this.state.searchPage + 1);
    }
  }

  setManufacturer(manufacturer) {
    if (this.state.manufacturer !== manufacturer) {
      this.setState({
        manufacturer,
        welcomeText: false,
      });
    }
  }

  setSearchText(searchText) {
    if (this.state.searchText !== searchText) {
      this.setState({
        searchText,
        welcomeText: false,
      });
    }
  }

  setCategory(categoryId) {
    console.log('Setting category to:', categoryId);
    if (this.state.categoryId !== categoryId) {
      this.setState({
        categoryId,
        welcomeText: false,
      });
    }
  }

  updateSelectedProducts(selectedProducts) {
    this.setSelectedProducts(selectedProducts);
    this.setState({
      selectedProducts,
      loading: false,
    });
  }

  addToSelectedProducts(product) {
    const selected = this.state.selectedProducts;
    if (selected.length >= maxSelectedProducts) {
      this.setState({
        snackbar: 'Du har max antal tillåtna produkter i din lista',
      });
      return;
    }

    const i = selected.indexOf(product);
    console.log('Selected Product:', product);
    if (i === -1) {
      selected.push(product);
      this.updateSelectedProducts(selected);
    }
  }

  removeFromSelectedProducts(product) {
    const selected = this.state.selectedProducts;
    this.updateSelectedProducts(selected.filter((elem) => elem.id !== product.id));
  }

  goToDetails() {
    this.redirect('/details');
  }

  onUserListLoaded(products) {
    this.updateSelectedProducts(products);
  }

  onResetClicked() {
    this.updateSelectedProducts([]);
  }

  renderSidenav() {
    return (
      <SidebarView
        onManufacturerChanged={this.setManufacturer.bind(this)}
        onSearchTextChanged={this.setSearchText.bind(this)}
        onCategoryChanged={this.setCategory.bind(this)}
        onResetClicked={this.onResetClicked.bind(this)}
        categoryId={this.state.categoryId}
        searchText={this.state.searchText}
        manufacturer={this.state.manufacturer}
        selectedProducts={this.state.selectedProducts}
      />
    );
  }

  renderContent() {
    const selectedProducts = this.state.selectedProducts;

    const clearDisabled = selectedProducts.length === 0;

    if (this.state.loading) {
      return <LoadingContainer className="loadingContent" enabled />;
    }

    if (this.state.welcomeText && !selectedProducts.length) {
      return (
        <div className="alignCenter">
          <div className="welcomeText">
            <img src={logo} style={{width: '400px'}} />
            <h2>Välkommen till Matpriskollens prisanalys!</h2>
            Börja med att söka fram vilka produkter du vill jämföra till vänster och klicka sedan på "Visa detaljer" för att jämföra.
            <br />
            Du kan spara sökningar och det finns olika diagram och du kan exportera data till excel.
          </div>
        </div>
      );
    }

    return (
      <div className="productList">
        {(true || selectedProducts.length > 0) && (
          <ResultList
            className="selected"
            products={selectedProducts}
            selectedProducts={selectedProducts}
            noResult="Välj produkter ur sökresultatet för att visa detaljer"
            onAddProduct={this.addToSelectedProducts.bind(this)}
            onRemoveProduct={this.removeFromSelectedProducts.bind(this)}>
            <nav>
              <div className="detailsButton">
                {
                  <Button variant="contained" onClick={this.goToDetails.bind(this)} disabled={this.state.selectedProducts.length === 0}>
                    <FontAwesomeIcon icon={faChartLine} style={{ color: '#FFF', marginRight: '5px' }} /> Visa Detaljer
                  </Button>
                }
              </div>
            </nav>
            <div className="loadSaveButtons">
              <UserListSaveDialog className={'actions'} products={selectedProducts} disabled={selectedProducts.length < 1} />
              <UserListLoadDialog className={'actions'} onLoad={this.onUserListLoaded.bind(this)} />
            </div>
            <span className="listHeader">{`Valda produkter (${selectedProducts.length} av ${maxSelectedProducts} stycken)`}</span>
            <div className="clearButton">
              <Button variant="text" onClick={this.onResetClicked.bind(this)} disabled={clearDisabled} startIcon={<ClearIcon />}>
                Rensa sökning
              </Button>
            </div>
          </ResultList>
        )}

        <div className="divider" />

        <ResultList
          className="search"
          header={'Sökresultat'}
          products={this.state.searchResult}
          selectedProducts={selectedProducts}
          onAddProduct={this.addToSelectedProducts.bind(this)}
          onRemoveProduct={this.removeFromSelectedProducts.bind(this)}
          onScrollToBottom={this.handleScrollToBottom.bind(this)}
          loading={this.state.searchLoading}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="app container">
        <div className="content">{this.isThirdPartyCookiesEnabled ? this.renderContent() : <ErrorView />}</div>

        <Drawer width="26%" className={'sidenav'} variant="permanent" PaperProps={{ elevation: 5 }} open>
          {this.renderSidenav()}
        </Drawer>

        <Snackbar open={!!this.state.snackbar} message={this.state.snackbar} autoHideDuration={3000} onRequestClose={() => this.setState({ snackbar: '' })} />
      </div>
    );
  }
}

export default App;
