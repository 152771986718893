import Backend from 'services/Backend';
import Product from 'model/Product';
import Store from 'model/Store';
import Price from 'model/Price';

class DataManager {
  products = {};
  stores = {};
  prices = {};

  startDate = null;
  endDate = null;

  constructor(startDate, endDate) {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  _fetchPricesPromise(products) {
    const promises = products.map((product) => Backend.fetchPrices(product.id, this.startDate, this.endDate));

    return Promise.all(promises);
  }

  fetchProductPrices(selectedProducts) {
    this.clear();
    let promises = [];

    selectedProducts.forEach((product, index) => {
      if (product.type !== 3 && !product.varumarke) {
        // Hämtar o lägger till varumärke till produkten så den syns i detaljer
        promises.push(Backend.fetchProductManufacturer(product.id).then((varumarkeResult) => {
          product.varumarke = varumarkeResult.namn;
        }));
      }
    });
    
    return Promise.all(promises).then(_ => this._fetchPricesPromise(selectedProducts, this.startDate, this.endDate)).then((fetchedPrices) => {
      // Setting multiple GTIN if it is a combined product.
      selectedProducts.forEach((product, index) => {
        const prodInfo = [];
        const gtin = new Set();
        fetchedPrices[index]
          .map((productslist) => productslist.products)
          .forEach((products) =>
            products.forEach((product) => {
              gtin.add(product.info.gtin);
              prodInfo.push({
                gtin: product.info.gtin,
                name: product.info.name,
                name2: product.info.name2,
                lastPrice: new Date(product.prices[product.prices.length - 1].originalDate),
                manufacturer: product.info.manufacturer,
              });
            })
          );

        const prodInfoToShow = [];
        gtin.forEach((x) => {
          const info = prodInfo.reduce((acc, info) => {
            if ((!!acc && acc.lastPrice > info.lastPrice) || info.gtin !== x) {
              return acc;
            }
            return info;
          }, null);
          prodInfoToShow.push(info);
        });

        if (prodInfoToShow.length > 1) {
          prodInfoToShow.map((x) => (x.lastPrice = x.lastPrice.toISOString().slice(0, 10)));
          product.combineInfo = prodInfoToShow;
        }
      });

      return Backend.fetchChainNames().then((chainNames) => {
        selectedProducts.forEach((product, index) => {
          const productPrices = [];
          fetchedPrices[index].forEach((data) => {
            const storeData = data.store;
            const store = this.stores[storeData.id] || new Store(this, storeData, chainNames);
            const prices = [];

            data.products.forEach((prod) => {
              const innerProductPrices = [];
              innerProductPrices.push.apply(
                innerProductPrices,
                prod.prices.map((price) => new Price(price, data.store.id, prod.info.unit, prod.info.amountOfUnits))
              );
              prices.push(innerProductPrices);
            });
            store.addProduct(product.id, prices);
            this.stores[storeData.id] = store;
            productPrices.push(prices);
          });

          this.products[product.id] = new Product(this, product, productPrices);
        });

        return this.getProducts();
      });
    });
  }

  sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
  }

  getPriceAtTime(time, prices) {
    for (let i = prices.length - 1; i >= 0; i--) {
      const price = prices[i];

      if (time >= price.datetime) {
        if (price.lastDateTime) {
          if (time <= price.lastDateTime) {
            return price;
          }

          return null;
        }
        return price;
      }
    }

    return null;
  }

  getPricesFullRange(innerProductPrices, priceDates) {
    const finalDate = new Date(this.endDate);
    finalDate.setHours(23, 59, 59, 999);
    const startDate = new Date(this.startDate);
    startDate.setHours(0, 0, 0, 0);

    const dates = [startDate];
    let dateIndex = 0;
    while (dates[dateIndex] < finalDate) {
      const dateCopy = new Date(dates[dateIndex]);
      const nextDate = dateCopy.setDate(dateCopy.getDate() + 1);
      if (nextDate >= finalDate) {
        break;
      }
      dates.push(nextDate);
      dateIndex++;
    }

    return innerProductPrices.map((cpPrices) => {
      if (cpPrices.length > 0) {
        const firstPrice = cpPrices[0];

        return dates.map((date) => {
          const p = this.getPriceAtTime(date, cpPrices);
          if (p !== null) {
            const s = p.clone();
            s.setDate(date);
            return s;
          }

          const noPrice = firstPrice.clone();
          noPrice.setDate(date);
          noPrice.amount = null;
          noPrice.discountAmount = null;
          noPrice.compareAmount = null;
          noPrice.compareDiscountAmount = null;

          return noPrice;
        });
      }
    });
  }

  getStores() {
    return Object.keys(this.stores).map((key) => this.stores[key]);
  }

  getActiveStores() {
    return this.getStores().filter((store) => store.active);
  }

  getProducts() {
    return Object.keys(this.products).map((key) => this.products[key]);
  }

  getActiveProducts() {
    return this.getProducts().filter((product) => product.active);
  }

  getProductsByIds(ids) {
    return ids.map((id) => this.products[id]);
  }

  getStoresByIds(ids) {
    return ids.map((id) => this.stores[id]);
  }

  clear() {
    this.products = [];
    this.stores = [];
    console.log('Cleared cache for Prices');
  }
}

export default DataManager;
