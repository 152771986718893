import ica from 'images/stores/ica.png';
import willys from 'images/stores/willys.jpg';
import citygross from 'images/stores/citygross.png';
import hemkop from 'images/stores/hemkop.jpg';
import coop from 'images/stores/coop.png';
import matse from 'images/stores/mat_se.png';
import mathem from 'images/stores/mathem.jpg';

// public enum ChainName { ICA, WILLYS, HEMKOP, COOP, MATSE, MATHEM, CITYGROSS }
export function getStoreImageUrl(chain) {
  switch(chain) {
  case 0:
    return ica;
  case 1:
    return willys;
  case 2:
    return hemkop;
  case 3:
    return coop;
  case 4:
    return matse;
  case 5:
    return mathem;
  case 6:
    return citygross;
  default:
    return '';
  }
}
