import React from 'react';
import dateFormat from 'dateformat';
import Avatar from '@mui/material/Avatar';
import './ProductInfo.css';
import Backend from '../../../services/Backend';
import { Card, CardHeader, CardContent } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import IconButton from '@mui/material/IconButton';
import { Collapse } from '@mui/material';

import { formatProductVolume } from '../../../services/Utils';

class ProductInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state.product = this.props.selectedProduct;
  }

  state = {
    product: [],
    combineInfo: [],
    expanded: false,
  };

  componentDidUpdate() {}
  componentDidMount() {
    const { product } = this.state;
    if (product.type === 3) {
      // hämta kombinerade prod info
      Backend.fetchCombinedProducts(product.id).then((response) => {
        if (response.length > 0) {
          // eslint-disable-next-line react/no-direct-mutation-state
          this.state.combineInfo = response.map((x) => {
            return (
              <div className="item box" key={x.key}>
                <div>
                  <b>Varumärke: </b> {x.varumarke}{' '}
                </div>
                <div>
                  <b>Namn: </b> {x.namn}{' '}
                </div>
                <div>
                  <b>Mängd: </b>
                  <span> {formatProductVolume(x.mangd, x.enhet)} </span>
                </div>
                <div>
                  <b>GTIN: </b> {x.gtin}{' '}
                </div>
              </div>
            );
          });
        } else {
          // eslint-disable-next-line react/no-direct-mutation-state
          this.state.combineInfo = null;
        }
      });
    }
  }

  handleExpandChange = (expanded) => {
    this.setState({ expanded: expanded });
  };

  getDuplicateName(product) {
    if (product.type === 3) {
      return product.name2;
    }
    return formatProductVolume(product.amountOfUnits, product.unit);
  }

  maxChars(count, text) {
    return text && text.length > count
      ? text.substring(0, count - 3) + '...'
      : text;
  }

  render() {
    const { product, combineInfo, expanded } = this.state;

    return (
      <Card elevation={2} sx={{ marginTop: 3 }}>
        <CardHeader
          title={product.name}
          subheader={
            <>
              {this.maxChars(200, this.getDuplicateName(product))}
              <br />
              {product.manufacturer || product.varumarke}
            </>
          }
          action={
            <IconButton
              size="large"
              edge={'end'}
              onClick={() => {
                this.setState({ expanded: !expanded });
              }}
              aria-label="show more"
            >
              {expanded ? (
                <ExpandLess fontSize="inherit" />
              ) : (
                <ExpandMoreIcon fontSize="inherit" />
              )}
            </IconButton>
          }
          // actAsExpander
          // showExpandableButton
          avatar={
            <Avatar
              crossOrigin={'anonymous'}
              sx={{
                backgroundColor: Backend.getProductBackgroundColor(product),
                img: { objectFit: 'contain' },
                width: '80px',
                height: '80px',
              }}
              src={Backend.getProductPictureURL(product)}
              id={product.id}
              alt={product.name}
              variant="square"
            />
          }
        />

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {expanded && product && (
              <div className="productInfo">
                {product.type !== 3 && (
                  <div>
                    <div>
                      {' '}
                      <b> Varumärke:</b> {product.varumarke} <br />
                    </div>
                    <div>
                      <b> Antal:</b> {product.numberOfItems}{' '}
                    </div>
                    <div>
                      <b> Mängd:</b>{' '}
                      {formatProductVolume(product.amountOfUnits, product.unit)}
                    </div>
                    <span>
                      {' '}
                      <b> GTIN:</b> {product.gtin} <br />
                    </span>
                  </div>
                )}
                {product.name2 && (
                  <div>
                    <b> Info:</b> {product.name2}
                  </div>
                )}
                {product.getDate() && (
                  <div>
                    <b>Senaste prisändring:</b>{' '}
                    {dateFormat(product.getDate(), 'yyyy-mm-dd')}
                  </div>
                )}
                {expanded && combineInfo !== null && product.type === 3 ? (
                  <div className="combined-header">Kombinerade:</div>
                ) : null}
                {expanded && combineInfo !== null && product.type === 3 ? (
                  <div className="combinedProductInfo">{combineInfo}</div>
                ) : null}
              </div>
            )}{' '}
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

export default ProductInfo;
