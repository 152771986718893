import xhr from "xhr";
import React from "react";
import { createBrowserHistory } from "history";
import moment from "moment";
import { localAndSessionstorageAvailable } from "./Utils";
import { v4 as uuidv4 } from "uuid";
export default class BackendMPK2 extends React.Component {
  static TOKEN_KEY = "TokenKeyForAuthorization";
  browserHistory = createBrowserHistory();

  static getToken() {
    const params = this.getParams();

    if (!localAndSessionstorageAvailable()) {
      window.location.href = "/error?code=999";
    } else if (params.token) {
      sessionStorage.setItem(this.TOKEN_KEY, params.token);
      this.redirectWithoutParam("token", params.token);
    }

    return sessionStorage.getItem(this.TOKEN_KEY);
  }

  static redirectWithoutParam(key, value) {
    const url = window.location.href.replace(`${key}=${value}`, "");
    // TODO:FIXA

    console.log("%c⧭", "color: #408059", url);
    // this.browserHistory.push(url);
  }

  static getParams() {
    const paramsStr = window.location.search || "?";
    const params = {};
    paramsStr
      .slice(1)
      .split("&")
      .forEach((paramStr) => {
        const index = paramStr.indexOf("=");
        const key = paramStr.slice(0, index);
        const value = paramStr.slice(index + 1);
        params[key || ""] = value;
      });

    return params;
  }

  static getBaseURL() {
    const hostName = window.location.hostname;

    if (
      ["pricesfe.test.matpriskollen.io"].includes(
        hostName,
      )
    ) {
      return "https://adminapi.test.matpriskollen.io/api";
    }

    if (["pricesfe.matpriskollen.io"].includes(hostName)) {
      return "https://adminapi.matpriskollen.io/api";
    }

    return "https://adminapi.test.matpriskollen.io/api";
  }

  static getProductPictureURL(produkt) {
    if (produkt.produktBildThumbnail && produkt.produktBildThumbnail.url) {
      return produkt.produktBildThumbnail.url;
    }

    return "no-image";
  }

  static request(method, pathUrl, data) {
    const url = `${this.getBaseURL()}${pathUrl}`;
    const token = BackendMPK2.getToken();
    const headers = {
      "Content-Type": "application/json",
      "X-Correlation-ID": uuidv4().toString(),
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const req = {
      url: url,
      headers: headers,
      method: method,
    };

    if (data) {
      req.body = JSON.stringify(data);
      headers["Content-Type"] = "application/json";
    }

    return new Promise((resolve, reject) => {
      xhr(req, (err, response) => {
        if (err) {
          reject(err);
        }

        const statusCode = response.statusCode;
        if (statusCode >= 400) {
          if (statusCode <= 403 || (statusCode >= 500 && statusCode < 600)) {
            console.error(`Error ${statusCode}`, response);
            // TODO:FIXA
            this.browserHistory.push(`/error?code=${statusCode}`);
          }

          reject(response.statusCode);
        }

        resolve(JSON.parse(response.body));
      });
    });
  }

  static delete(pathUrl) {
    return this.request("DELETE", pathUrl);
  }

  static get(pathUrl) {
    return this.request("GET", pathUrl);
  }

  static put(pathUrl, data) {
    return this.request("PUT", pathUrl, data);
  }

  static post(pathUrl, data) {
    return this.request("POST", pathUrl, data);
  }

  static getVarumarken() {
    return this.get("/varumarke/namn/");
  }

  static getPriser(produktId, startDatum, stoppDatum) {
    const from = moment(startDatum).format("YYYYMMDD");
    const to = moment(stoppDatum).format("YYYYMMDD");
    return this.get(`/pris/${produktId}/priser?to=${to}&from=${from}`).then(
      (r) => {
        return r.butiker.map((b) => this.prisButikToStorePrice(b));
      },
    );
  }

  static getProdukt(id) {
    return this.get(`/produkt/${id}`).then((produkt) => {
      return this.translateProdukt(produkt);
    });
  }

  static getCombinedProdukter(produktKey) {
    return this.get(`/produkt/${produktKey}/combined`).then((combined) => {
      return combined;
    });
  }

  static getSearchResults(searchText, varumarke, kategori, page) {
    const urlPrefix = "/produkt/search?q=";
    const urlSuffix = "&amount=20&page=" + page;
    let url = urlPrefix + encodeURI(searchText) + urlSuffix;
    if (varumarke) {
      url += "&manufacturer=" + encodeURI(varumarke);
    }
    if (kategori) {
      url += "&categoryId=" + encodeURI(kategori);
    }
    return this.get(url).then((r) => {
      return r.map((r) => this.translateProduktSearchResult(r));
    });
  }

  static getKedjorNamn() {
    return Promise.resolve([
      "ICA", // 0
      "Willys", // 1
      "Hemköp", // 2
      "Coop", // 3
      "Mat.se", // 4
      "MatHem", // 5
      "City Gross", // 6
      "Netto", // 7
      "Lidl", // 8
      "Ö&B", // 9
      "Gekås", // 10
      "Rusta", // 11
      "Dollarstore", // 12
      "Normal", // 13
      "Willys Hemma", // 14
      "Eko Stormarknad", // 15
      "Costco", // 16
    ]);
  }

  static getKategorier() {
    return this.get(
      "/kategori/kategoritrad?includeproduktgrupp=true&includeEjSynlig=false",
    ).then((r) => {
      const newTree = r.map((t) => this.translateKategoritrad(t));
      return {
        name: "Alla kategorier",
        children: newTree,
        id: "00000000-0000-0000-0000-000000000000",
      };
    });
  }

  static getPrisanalysBevakningar() {
    return this.get("/prisanalysbevakning").then((r) => {
      return r.map((b) => this.translatePrisanalyBevakning(b));
    });
  }

  static getProduktVarumarke(produktKey) {
    return this.get(`/Produkt/${produktKey}/varumarke`);
  }

  static savePrisanalysBevakning(list) {
    const bevakning = this.translateUserList(list);
    if (bevakning._key) {
      return this.put(`/prisanalysbevakning/${bevakning._key}`, bevakning);
    }
    return this.post("/prisanalysbevakning", bevakning);
  }

  static getPrisanalysbevakningProdukter(bevakningId) {
    return this.get(`/prisanalysbevakning/${bevakningId}/produkter`).then(
      (r) => {
        return r.map((r) => this.translateProdukt(r));
      },
    );
  }

  static deletePrisanalysbevakning(bevakningId) {
    return this.delete(`/prisanalysbevakning/${bevakningId}`);
  }

  // Conversions
  static prisButikToStorePrice(prisButik) {
    return {
      store: this.translateButik(
        prisButik.butik,
        prisButik.block,
        prisButik.kedja,
      ), // Translate
      products: prisButik.produkter.map((p) => this.translatePrisProdukt(p)),
    };
  }

  static translateButik(butik, block, kedja) {
    let chainCode;
    switch (block.kod) {
      case 0: // Övriga privata
        switch (kedja.kod) {
          case "mathem":
            chainCode = 5;
            break;
          case "gekas":
            chainCode = 10;
            break;
          case "normal":
            chainCode = 13;
            break;
          case "eko-stormarknad":
            chainCode = 15;
            break;
          case "costco":
            chainCode = 16;
            break;
          default:
            chainCode = -1;
            break;
        }
        break;
      case 1:
        chainCode = 0;
        break; // ICA
      case 2:
        chainCode = 3;
        break; // COOP /Netto?
      case 3: // Axfood
        switch (kedja.kod) {
          case "willys":
            chainCode = 1;
            break;
          case "hemkop":
            chainCode = 2;
            break;
          case "matse":
            chainCode = 4;
            break;
          case "willys-hemma":
            chainCode = 14;
            break;
          default:
            chainCode = -1;
            break;
        }
        break;
      case 4:
        chainCode = 6;
        break; // Bergendahls (Citygross)
      case 5:
        chainCode = 8;
        break;
      case 6:
        chainCode = 9;
        break;
      case 7:
        chainCode = 11;
        break;
      case 8:
        chainCode = 12;
        break;
      default:
        chainCode = -1;
        break;
    }
    return {
      id: butik._key,
      created: butik.created,
      storeId: butik.externId,
      name: butik.namn,
      type: kedja.kod,
      chain: chainCode,
      city: butik.ort,
      country: butik.landskod,
      postCode: butik.postkod,
      address: butik.adress,
      lat: butik.lat,
      lon: butik.lon,
      color: kedja.fargkod || butik.fargkod,
      info: butik.info,
      visible: butik.synlig,
    };
  }

  static translatePrisProdukt(prisProdukt) {
    const product = this.translateProdukt(prisProdukt.produkt);
    return {
      info: product,
      prices: prisProdukt.priser.map((p, index) =>
        this.translatePris(p, index == prisProdukt.priser.length - 1),
      ),
    };
  }

  static translatePris(prisWithDates, isLast) {
    const pris = prisWithDates.pris;

    return {
      id: pris._key,
      date: prisWithDates.startDatum,
      originalDate: prisWithDates.spindlingDatum,
      amount: pris.ordPris,
      currency: pris.valuta,
      discountAmount: pris.kampanjPris,
      onlyMembers: pris.medlemsPris,
      lastDate: prisWithDates.stoppDatum,
      sourceComparePrice: pris.obehandlatJmfPris,
      compareAmount: pris.ordjmfPris,
      compareDiscountAmount: pris.kampanjJmfPris,
      compareUnit: pris.jmfEnhet,
    };
  }

  static translateProduktSearchResult(result) {
    const produkt = this.translateProdukt(result.Produkt);
    produkt.numberOfPrices = 1;
    produkt.chains = result.Block;
    produkt.categories = [];
    produkt.lastCrawled = result.SenastSpindlad;
    produkt.manufacturer =
      result.Varumarken &&
      result.Varumarken.length > 0 &&
      result.Varumarken[0].namn;
    if (result.HuvudKategori) {
      produkt.categories.push(result.HuvudKategori._key);
    }
    if (result.Kategori) {
      produkt.categories.push(result.Kategori._key);
    }
    if (result.ProduktGrupp) {
      produkt.categories.push(result.ProduktGrupp._key);
    }
    return produkt;
  }

  static translateProdukt(produkt) {
    return {
      id: produkt._key ? produkt._key : produkt.key,
      gtin: produkt.gtin,
      name: produkt.namn,
      name2: produkt.produktinfo,
      manufacturer: "",
      numberOfItems: produkt.antal,
      imageUrl: "",
      unit: produkt.enhet,
      amountOfUnits: produkt.mangd,
      isVisible: produkt.synlig,
      incrementValue: produkt.minstaDelmangd,
      type: produkt.produktTyp,
      varumarke: produkt.varumarke,
      categories: [],
      dataText: "",
      produktBild: produkt.produktBild,
      produktBildThumbnail: produkt.produktBildThumbnail,
    };
  }

  static translateKategoritrad(kategoritrad) {
    const children = kategoritrad.children.map((c) =>
      this.translateKategoritrad(c),
    );
    return {
      children: children,
      name: kategoritrad.namn,
      id: kategoritrad.id,
      parent: null,
      categoryId: kategoritrad.namn,
    };
  }

  static translatePrisanalyBevakning(bevakning) {
    return {
      id: bevakning._key,
      name: bevakning.namn,
      email: bevakning.email,
      all: bevakning.alla,
      categoryId: bevakning.kategoriId,
      products: bevakning.produkter,
    };
  }

  static translateUserList(list) {
    return {
      _key: list.id,
      namn: list.name,
      email: list.email,
      alla: list.all,
      kategoriId: list.categoryId,
      produkter: list.products,
    };
  }
}
