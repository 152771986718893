import React from 'react';
import Paper from '@mui/material/Paper';
import LastPricesChart from '../chart/LastPricesChart';
import ProductInfo from 'components/products/info/ProductInfo';
import './LastPricesDetail.css';

class LastPricesDetail extends React.Component {
  render() {
    // const style = {
    //   margin: '0.5em',
    // };

    const style = {
      margin: '0.5em',
      minWidth: '98%',
      display: 'inline-block',
    };

    const product = this.props.product;

    return (
      <Paper style={style} elavation={3}>
        <ProductInfo selectedProduct={product} />
        <br />
        <LastPricesChart
          className={'lastpriceschart'}
          product={product}
          height={400}
          showDiscountPrices={this.props.showDiscountPrices}
          showComparePrices={this.props.showComparePrices}
        />
      </Paper>
    );
  }
}

export default LastPricesDetail;
