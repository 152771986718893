import xhr from 'xhr';
import React from 'react';
import { browserHistory } from 'react-router';
import BackendMPK2 from './BackendMPK2';

export default class Backend extends React.Component {
  static getProductPictureURL(produkt) {
    return BackendMPK2.getProductPictureURL(produkt);
  }

  static getProductBackgroundColor(produkt) {
    if (produkt.produktBildThumbnail && produkt.produktBildThumbnail.url) {
      return null;
    }

    return 'rgb(210, 41, 13)';
  }

  static fetchBrands() {
    return BackendMPK2.getVarumarken();
  }

  static fetchPrices(productId, startDate, endDate) {
    return BackendMPK2.getPriser(productId, startDate, endDate);
  }

  static fetchProduct(id) {
    return BackendMPK2.getProdukt(id);
  }

  static async fetchProductManufacturer(key) {
    return BackendMPK2.getProduktVarumarke(key);
  }

  static fetchSearchResults(searchText, manufacturer, categoryId, page) {
    return BackendMPK2.getSearchResults(searchText, manufacturer, categoryId, page);
  }

  static fetchChainNames() {
    return BackendMPK2.getKedjorNamn();
  }

  static fetchCategories() {
    return BackendMPK2.getKategorier();
  }

  static fetchCombinedProducts(produktKey) {
    return BackendMPK2.getCombinedProdukter(produktKey);
  }

  static saveUserList(list) {
    return BackendMPK2.savePrisanalysBevakning(list);
  }

  static fetchUserLists() {
    return BackendMPK2.getPrisanalysBevakningar();
  }

  static fetchUserListProducts(listId) {
    return BackendMPK2.getPrisanalysbevakningProdukter(listId);
  }

  static deleteUserList(listId) {
    return BackendMPK2.deletePrisanalysbevakning(listId);
  }
}
