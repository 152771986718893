
class Product {
  active = true;

  date = null;
  prices = [];
  storeIds = [];

  constructor(manager, product, prices) {
    Object.assign(this, product);
    this.manager = manager;
    this.prices = [].concat(...prices);

    /* Flattens the argument "prices" to one array
    to check last updated of all products it may contain */
    const pricesFlattened = [].concat(...this.prices);

    this.date = pricesFlattened.reduce((acc, price) => {
      const date = new Date(price.originalDate);
      if (!!acc && acc > date) {
        return acc;
      }
      return date;
    }, null);
    this.storeIds = pricesFlattened.map(price => price.storeId)
      .filter((v, i, a) => a.indexOf(v) === i);
  }

  getStore() {
    return this.manager.getStoresByIds(this.storeIds);
  }

  getActiveStores() {
    return this.getStore().filter(store => store.active);
  }

  getDate() {
    return this.date;
  }

  getPrices() {
    return this.prices || [];
  }

  getPricesByStore(storeId, priceDates) {
    const prices = [this.prices.map(innerPrice =>
      innerPrice.filter(x => x.storeId === storeId)
    ).filter(arr => arr.length > 0).flatMap(x => x)];
    return this.manager.getPricesFullRange(prices, priceDates);
  }
}

export default Product;
