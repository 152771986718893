import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './containers/app/App';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
} from 'react-router-dom';
import DetailView from 'containers/details/DetailView';
import ErrorView from 'containers/error/Error';

function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);
  return <div>{error.message}</div>;
}

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(210, 41, 13)',
    },
    secondary: {
      main: '#444',
    }
  },
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/details',
    element: <DetailView />,
  },
  {
    path: '/error',
    element: <ErrorView />,
  },
  {
    path: '*',
    element: <App />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <RouterProvider router={router} />
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
